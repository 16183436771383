import { jwtDecode } from "jwt-decode"
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"

import { ApiConnection } from "../../api/api-client/common/ApiConnection"
import { ApiController } from "../../api/apiController"
import { refresh } from "../../api/lib/auth/auth"
import useIsIdleStateTimer from "../../hooks/useIsIdleStateTimer"
import { checkForParamInUrlSearchParams } from "../../utils/auth/lib/checkForParamInUrlSearchParams"
import { connectToApiAndReturnUser } from "../../utils/auth/lib/connectToApiAndReturnUser"
import { loginTypes } from "../../utils/auth/loginTypes"
import { PAGE_PATH } from "../application/constants"
import { useApplicationContext } from "../application/context"
import { usePlatformContext } from "../platform/platformContextProvider"
import { useThumbnailContext } from "../thumbnails"
import { useToastContext } from "../toasts"
import { useUserContext } from "../users"
import { LocalStorageKeys } from "./localStorageKeys"

export type LocalStorageKey = string | null

type UserInfo = {
  token: LocalStorageKey
  apiKey: LocalStorageKey
  userName: LocalStorageKey
}
interface AuthContextState {
  userInfo: UserInfo
  setUserInfo: (userInfo: UserInfo) => void
  clearLocalStorage: () => void
  logout: () => void
  isAuthenticated: () => boolean
}

interface AuthContextProps {
  children: React.ReactNode
}

const initialAuthContextState: AuthContextState = {
  setUserInfo: (userInfo: UserInfo) => {},
  userInfo: { token: null, apiKey: null, userName: null },
  clearLocalStorage: () => {},
  logout: () => {},
  isAuthenticated: () => false,
}

export const AuthContext = createContext<AuthContextState>(
  initialAuthContextState
)

// The token is set to expire 1 minute before the actual expiry time, this is to avoid the token expiring while the user is using the app.
const authTokenExpiryTime = +(process.env.API_TOKEN_EXPIRY_MINUTES ?? 15) - 1

// Decoding the token to get the expiry time.
// We should not get an error here (BE should always return good tokens), but if we do, we will return 0.
// Ideally BE should return the expiry time as well.
const getExpiresAt = (token: LocalStorageKey) => {
  let expiresAt = 0
  try {
    expiresAt = jwtDecode(token ?? "")?.exp ?? 0
  } catch (e) {
    console.error("Error decoding token")
  }
  return expiresAt
}

export const AuthContextProvider = ({ children }: AuthContextProps) => {
  const { dispatch } = useApplicationContext()
  const { dispatch: dispatchUser } = useUserContext()
  const { dispatch: thumbnailDispatch } = useThumbnailContext()
  const { dispatch: toastDispatch } = useToastContext()

  const navigate = useNavigate()
  const { isIdle, setIsIdle } = useIsIdleStateTimer()
  const { loginType, gaTrackingId } = usePlatformContext()

  const [authState, setAuthState] = useState<{
    userInfo: UserInfo
    expiresAt: number
  }>({ userInfo: { token: null, apiKey: null, userName: null }, expiresAt: 0 })

  // This method is used to refresh the token and save it to the local storage and the state.
  const refreshAndSaveToken = useCallback(async () => {
    try {
      const apiKey = localStorage.getItem(LocalStorageKeys.ApiKey)
      if (loginType !== loginTypes.PASSWORD) {
        // In this case we need to connect to the apiController so is setup correctly, we should set it up with shouldAutoRefreshToken = true.
        const apiConnection = new ApiConnection(apiKey!, "")
        await connectToApiAndReturnUser({
          apiConnection,
          token: localStorage.getItem(LocalStorageKeys.Token) ?? "",
          dispatch,
          dispatchUser,
          thumbnailDispatch,
          toastDispatch,
          shouldAutoRefreshToken: true,
          gaTrackingId,
        })

        return
      }
      // When using the password login type, we need to refresh the token via refresh endpoint.
      // to not confused with refresh endpoint used by apiController where we need to pass the "code" from refreshToken in the local storage.
      const responseTokenRefreshed = await refresh()
      localStorage.setItem(LocalStorageKeys.Token, responseTokenRefreshed)
      const userName = localStorage.getItem(LocalStorageKeys.UserName)
      setAuthState({
        userInfo: {
          token: responseTokenRefreshed ?? "",
          apiKey,
          userName,
        },
        expiresAt: getExpiresAt(responseTokenRefreshed),
      })

      //backwards compatibility for the old api controller calls but in this case the shouldAutoRefreshToken should be false because loginType password has its own refresh mechanism.
      const apiConnection = new ApiConnection(apiKey!, "")
      await connectToApiAndReturnUser({
        apiConnection,
        token: responseTokenRefreshed,
        dispatch,
        dispatchUser,
        thumbnailDispatch,
        toastDispatch,
        shouldAutoRefreshToken: false,
        gaTrackingId,
      })

      // We need to set the token in the api controller as well because the above has a check for "apiController.connected" (connectToApi.ts line 47)
      const apiController = ApiController.getInstance()
      apiController.setToken(responseTokenRefreshed)
    } catch (e) {
      console.error("Error when calling refreshing token", e)
      // If the refresh token fails, we remove the token from the local storage and the state.
      // In this rare case, the user will have to log in again.
      localStorage.removeItem(LocalStorageKeys.Token)
      localStorage.removeItem(LocalStorageKeys.ApiKey)
      localStorage.removeItem(LocalStorageKeys.UserName)
      localStorage.removeItem(LocalStorageKeys.IsPrimaryUser)
      setAuthState({
        userInfo: { token: null, apiKey: null, userName: null },
        expiresAt: 0,
      })
    }
  }, [
    dispatch,
    dispatchUser,
    loginType,
    thumbnailDispatch,
    toastDispatch,
    gaTrackingId,
  ])

  // This useEffect is used to schedule the token refresh every "X" minutes, this will run only once.
  // and particularly when there is a browser hard refresh / reload.
  // if the user is not authenticated(token is not in local storage), we don't need to refresh the token.
  useEffect(() => {
    const setIntervalForRefreshingToken = async () => {
      // if the loginType is empty it means that the app is not ready to start refreshing the token.
      if (!isAuthenticated() || loginType === "") {
        return
      }
      // We should have token in the local storage so we need to refresh it immediately and then we set the interval.
      await refreshAndSaveToken()
      setInterval(refreshAndSaveToken, authTokenExpiryTime * 60000)
    }

    setIntervalForRefreshingToken()
  }, [refreshAndSaveToken, loginType])

  useEffect(() => {
    const logout = () => {
      setIsIdle(false)
      clearLocalStorage()

      setAuthState({
        userInfo: {
          token: null,
          apiKey: null,
          userName: null,
        },
        expiresAt: 0,
      })
      navigate(PAGE_PATH.LoginPage)
    }
    if (isIdle) {
      logout()
    }
  }, [isIdle, setIsIdle, navigate])

  // This method should only be called when the user logs in.
  // It sets the token in the local storage and the state and schedules the token refresh.
  // We should trust the BE to return a good token.
  const setAuthStateInternal = (userInfo: UserInfo) => {
    const { token, apiKey, userName } = userInfo
    localStorage.setItem(LocalStorageKeys.Token, token ?? "")
    localStorage.setItem(LocalStorageKeys.ApiKey, apiKey ?? "")
    localStorage.setItem(LocalStorageKeys.UserName, userName ?? "")
    setAuthState({
      userInfo: {
        token,
        apiKey,
        userName,
      },
      expiresAt: getExpiresAt(token),
    })
    setInterval(refreshAndSaveToken, authTokenExpiryTime * 60000)
  }
  const clearLocalStorage = () => {
    localStorage.removeItem(LocalStorageKeys.Token)
    localStorage.removeItem(LocalStorageKeys.ApiKey)
    localStorage.removeItem(LocalStorageKeys.UserName)
    localStorage.removeItem(LocalStorageKeys.HasMultipleCases)
    localStorage.removeItem(LocalStorageKeys.SelectedCaseId)
    localStorage.removeItem(LocalStorageKeys.HasFilesSharedWith)
    localStorage.removeItem(LocalStorageKeys.IsCurrentStageAllowingUploads)
    localStorage.removeItem(LocalStorageKeys.HasUsedLoginPageToSignIn)
  }

  const logout = () => {
    clearLocalStorage()

    setAuthState({
      userInfo: {
        token: null,
        apiKey: null,
        userName: null,
      },
      expiresAt: 0,
    })
    navigate(PAGE_PATH.LoginPage)
  }

  const isAuthenticated = () => {
    const token = localStorage.getItem(LocalStorageKeys.Token)
    if (!token) {
      return false
    }

    // Token should never expired because of setIntervalForRefreshingToken, but we are checking just in case,
    // this could happen when the `refresh` endpoint fails or the token is not valid for example.
    const isTokenExpired = new Date().getTime() / 1000 > getExpiresAt(token)
    if (isTokenExpired) {
      return false
    }

    const params = new URLSearchParams(window.location.search.toLowerCase())
    const apiKey = checkForParamInUrlSearchParams(params, "apikey")
    const savedApiKey = localStorage.getItem(LocalStorageKeys.ApiKey)

    if (
      apiKey &&
      apiKey.toLocaleLowerCase() !== savedApiKey?.toLocaleLowerCase()
    ) {
      localStorage.removeItem(LocalStorageKeys.ApiKey)
      localStorage.removeItem(LocalStorageKeys.UserName)
      localStorage.removeItem(LocalStorageKeys.Token)
      localStorage.removeItem(LocalStorageKeys.HasMultipleCases)
      localStorage.removeItem(LocalStorageKeys.SelectedCaseId)
      localStorage.removeItem(LocalStorageKeys.HasFilesSharedWith)
      localStorage.removeItem(LocalStorageKeys.IsCurrentStageAllowingUploads)
      localStorage.removeItem(LocalStorageKeys.HasUsedLoginPageToSignIn)
      return false
    }

    return true
  }

  return (
    <AuthContext.Provider
      value={{
        userInfo: authState.userInfo,
        setUserInfo: (userInfo: UserInfo) => setAuthStateInternal(userInfo),
        logout,
        clearLocalStorage,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = (): AuthContextState => useContext(AuthContext)
