import { ReactNode, Suspense, useEffect } from "react"

import { MainLayoutWrapper } from "../component/layouts/MainLayoutWrapper"
import { LoadingFallBack } from "../component/organism/LoadingFallBack"
import {
  setAuthLoading,
  setSelectedTheme,
} from "../contexts/application/action"
import { useApplicationContext } from "../contexts/application/context"
import { usePlatformContext } from "../contexts/platform/platformContextProvider"
import { useThumbnailContext } from "../contexts/thumbnails"
import { useToastContext } from "../contexts/toasts"
import { useUserContext } from "../contexts/users"
import { loadUserData } from "../contexts/users/loaduserData"
import { TThemeName } from "../design-tokens/types"
import { useRefreshTokenOnTabFocus } from "../hooks/useRefreshTokenOnTabFocus"
import { apiConnect } from "../utils/auth/login"
import { loginTypes } from "../utils/auth/loginTypes"
import { AllRoutes } from "./AllRoutes/AllRoutes"

/**
 * Routing component.  basePath is only passed when running in the dock and tells our app which path it running on within the dock
 */
export const Routing = ({
  children,
  basePath,
}: {
  children?: ReactNode
  basePath?: string
}) => {
  const { dispatch } = useApplicationContext()
  const { loginType, themeName, gaTrackingId } = usePlatformContext()
  const {
    userState: { currentUser },
    dispatch: dispatchUser,
  } = useUserContext()
  const { dispatch: dispatchThumbnail } = useThumbnailContext()
  const { dispatch: toastDispatch } = useToastContext()

  useEffect(() => {
    apiConnect(
      dispatch,
      dispatchUser,
      dispatchThumbnail,
      toastDispatch,
      loginType,
      gaTrackingId
    )
    if (loginType === loginTypes.PASSWORD) {
      dispatch(setSelectedTheme(themeName as TThemeName))
      dispatch(setAuthLoading(false))
    }
  }, [
    dispatch,
    dispatchUser,
    dispatchThumbnail,
    loginType,
    themeName,
    toastDispatch,
    gaTrackingId,
  ])

  useEffect(() => {
    loadUserData({
      currentUser,
      dispatch,
      toastDispatch,
    })
  }, [currentUser, dispatch, loginType, toastDispatch])

  useRefreshTokenOnTabFocus()

  return (
    <MainLayoutWrapper basePath={basePath}>
      <Suspense fallback={<LoadingFallBack />}>
        <AllRoutes basePath={basePath} />
      </Suspense>
      {children}
    </MainLayoutWrapper>
  )
}
