import { lazy } from "react"
import { Route, Routes } from "react-router-dom"

import { IUser } from "../../../../api/api-client/api-types"
import {
  PAGE_PATH,
  UserModuleType,
} from "../../../../contexts/application/constants"
import ErrorPage from "../../../../pages/ErrorPage/ErrorPage"
import { castToBool } from "../../../../utils/helpers"
import { hasModules } from "../../utils/utils"
import { Redirect } from "../Redirect"

//Code Splitting for Communications

const DocumentHubPage = lazy(
  () =>
    import("../../../../pages/Communications/DocumentHubPage/DocumentHubPage")
)

const CommunicationsSearchPage = lazy(
  () =>
    import(
      "../../../../pages/Communications/CommunicationsSearchPage/CommunicationsSearchPage"
    )
)
const AnalyticsPage = lazy(
  () => import("../../../../pages/Communications/AnalyticsPage/AnalyticsPage")
)

export const DocumentHubRoutes = ({
  currentUser,
  basePath,
}: {
  currentUser: IUser
  basePath?: string
}) => {
  const isRunningInDock =
    castToBool(process.env.REACT_APP_RUNNING_IN_DOCK) ?? false

  return (
    <Routes>
      {hasModules([UserModuleType.DOCUMENT_HUB], currentUser) && (
        <>
          <Route
            path={
              isRunningInDock
                ? `${basePath}${PAGE_PATH.DocumentHubPage}`
                : PAGE_PATH.DocumentHubPage
            }
            element={<DocumentHubPage />}
          />
          <Route
            path={
              isRunningInDock
                ? `${basePath}${PAGE_PATH.AnalyticsPage}`
                : PAGE_PATH.AnalyticsPage
            }
            element={<AnalyticsPage />}
          />
          <Route
            path={
              isRunningInDock
                ? `${basePath}${PAGE_PATH.CommunicationsSearchPage}`
                : PAGE_PATH.CommunicationsSearchPage
            }
            element={<CommunicationsSearchPage />}
          />
        </>
      )}
      <Route path={PAGE_PATH.ErrorPage} element={<ErrorPage />} />
      <Route
        path={isRunningInDock ? basePath : "*"}
        element={<Redirect basePath={basePath} />}
      />
    </Routes>
  )
}
