export const replaceSpacesWithUnderscores = (text: string) => {
  if (text) {
    return text.replace(/ /g, "_")
  }
}

export const capitalizeFirstLetter = (text: string) => {
  if (text && text.length > 0) {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }
  return text
}

export const replaceNewLinesWithDotAndSpace = (text: string) => {
  return text.replace(/\n/g, ". ")
}

export const convertNewlinesToSentences = (text: string) => {
  return text
    .replace(/\s*\n+/g, "\n") // Remove whitespace before newlines and collapse multiple newlines
    .replace(/\n/g, (_, offset, str) => {
      // Look for the last non-whitespace character before the newline
      const prevText = str.slice(0, offset)
      const lastChar = prevText.trim().slice(-1)
      return lastChar === "." ? " " : ". "
    })
}
