export const WpsAdvisoryLogo = () => {
  return (
    <svg
      width="154"
      height="51"
      viewBox="0 0 154 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8002_10824)">
        <g clip-path="url(#clip1_8002_10824)">
          <path
            d="M124.137 22.1504L119.226 38.6709H115.089L112.291 28.8593L109.455 38.6709H105.318L100.407 22.1504H104.527L107.486 33.0579L110.531 22.1504H114.034L117.062 33.0579L120.02 22.1504H124.14H124.137Z"
            fill="#000080"
          />
          <path
            d="M132.99 22.1675C134.75 22.1675 136.175 22.784 137.267 23.986C138.36 25.2052 138.904 26.5613 138.904 28.099C138.904 29.6367 138.36 31.0305 137.25 32.2463C136.14 33.4655 134.682 34.0477 132.849 34.0477H128.942V38.6709H125.086V22.1504H133.007H132.99V22.1675ZM132.743 30.5511C133.428 30.5511 133.993 30.3045 134.432 29.8285C134.873 29.3524 135.099 28.7874 135.099 28.1333C135.099 27.4792 134.887 26.9141 134.466 26.4381C134.045 25.962 133.514 25.7155 132.88 25.7155H128.901V30.5682H132.719L132.736 30.5511H132.743Z"
            fill="#000080"
          />
          <path
            d="M146.668 39.0751C144.733 39.0751 143.075 38.5991 141.74 37.6265C140.401 36.6573 139.469 35.4724 138.942 34.0614L142.658 32.9142C142.993 33.7258 143.521 34.397 144.26 34.8902C145 35.3833 145.88 35.6505 146.918 35.6505C147.692 35.6505 148.308 35.51 148.784 35.1916C149.26 34.8731 149.507 34.5203 149.507 34.0785C149.507 33.3902 148.925 32.8594 147.73 32.4895L143.486 31.1847C142.343 30.832 141.428 30.2669 140.723 29.5066C140.038 28.7463 139.702 27.8491 139.719 26.808C139.719 25.4313 140.284 24.2669 141.445 23.3148C142.606 22.3628 144.069 21.8662 145.829 21.8662C147.589 21.8662 149.103 22.2909 150.442 23.1196C151.781 23.9655 152.678 24.9895 153.117 26.1916L149.524 27.2498C149.188 26.6676 148.695 26.1916 148.045 25.8183C147.394 25.4484 146.671 25.2703 145.88 25.2703C145.23 25.2703 144.702 25.3936 144.295 25.6573C143.891 25.9039 143.695 26.2395 143.695 26.6299C143.695 27.195 144.065 27.582 144.805 27.8114L148.959 29.1333C150.401 29.5751 151.51 30.1402 152.284 30.8285C153.058 31.4998 153.462 32.4895 153.462 33.76C153.462 35.26 152.846 36.4963 151.596 37.5032C150.346 38.51 148.709 39.0032 146.702 39.0032V39.0751H146.668Z"
            fill="#000080"
          />
          <path
            d="M26.9179 25.1439L26.9522 25.0514H26.9556L19.1953 1.48291C16.8734 2.09592 14.6577 2.79113 12.5583 3.56168C15.6405 13.5651 18.9556 23.5069 22.9762 33.1541V33.161C23.0378 33.3151 23.1063 33.4692 23.1714 33.6267C23.209 33.7158 23.2433 33.8014 23.2775 33.8904C23.507 34.4315 23.733 34.9795 23.9693 35.5206C23.894 35.7706 23.7947 36.0274 23.6816 36.2809C23.8974 35.8185 24.0173 35.524 24.0173 35.524L27.5857 27.1815L26.9145 25.1439H26.9179Z"
            fill="#000080"
          />
          <path
            d="M19.6542 39.2945C19.3665 39.2055 18.9864 39.0377 18.6919 38.8288C16.8289 37.4897 14.0925 29.3527 13.1473 26.8048C10.5378 19.7911 8.22269 12.661 6.01721 5.5C3.83912 6.58562 1.85282 7.76712 0.0822754 9.02055C2.47954 16.7055 5.00351 24.3527 7.88022 31.8562C8.95556 34.6678 10.3562 38.8493 12.3871 42.1507C12.8254 42.8596 13.2912 43.6507 13.8015 44.2603C13.8152 44.2603 14.0241 44.3116 14.0241 44.3116C19.0343 44.6473 22.0891 39.4349 23.3768 36.911C22.5617 38.411 21.2124 39.7089 19.6542 39.2945Z"
            fill="#000080"
          />
          <path
            d="M38.5343 11.7498C34.8494 11.0032 32.8425 13.8115 31.5035 16.7635C31.3288 17.2293 29.0548 23.2601 28.0514 25.9313C27.4898 27.5204 25.8357 31.1745 24.3596 34.6368C22.7261 38.421 19.8699 44.0717 14.0103 44.1745C13.5172 44.1642 14.0103 44.4895 14.1473 44.6847C14.9042 45.5032 15.798 46.2361 16.7877 46.7258C26.4658 50.7156 31.3802 36.5101 34.3288 28.6402C35.8151 24.4519 39.3836 14.9519 42.548 15.2258C41.7432 13.7156 40.2809 12.2156 38.5994 11.7772L38.5343 11.7567V11.7498Z"
            fill="#5323EF"
          />
          <path
            d="M49.1063 32.7019V32.6951C48.7673 31.7704 48.4385 30.8423 48.1166 29.9177C48.9282 27.8047 44.5583 39.0992 44.5583 39.0992C44.8803 39.9177 45.0549 40.3697 45.0549 40.3697C45.2193 40.7773 45.394 41.1917 45.5755 41.6129C46.8426 44.5684 48.4385 47.7602 50.4727 50.4656C53.2741 50.3149 56.007 50.0581 58.6645 49.7088C54.5036 45.7773 51.531 39.2738 49.1097 32.7019H49.1063Z"
            fill="#000080"
          />
          <path
            d="M76.8392 2.89029C74.7399 2.11974 72.5242 1.42454 70.2022 0.811523L62.442 24.38L62.4488 24.3971L61.5276 27.1985L65.1474 35.5273C65.4968 34.7293 65.829 33.9245 66.1646 33.1266V33.1197C70.305 23.2704 73.6851 13.1163 76.8427 2.89372L76.8392 2.89029Z"
            fill="#B85F27"
          />
          <path
            d="M88.9075 9.07523C87.1336 7.81839 85.1507 6.6403 82.9726 5.55469C80.7671 12.7156 78.4486 19.8458 75.8425 26.8595C74.8973 29.4006 72.1575 37.5444 70.2979 38.8835C70.0411 39.065 69.7911 39.1883 69.5479 39.2773C68.1815 39.6985 66.9692 38.7841 66.1267 37.5478C67.5925 40.1951 70.6918 44.4656 75.3082 44.154C75.8151 43.5444 76.2808 42.8732 76.7226 42.1643C78.774 38.8561 80.0308 34.743 81.1164 31.9074C83.9897 24.404 86.5171 16.7567 88.9144 9.07181H88.911L88.9075 9.07523Z"
            fill="#AF4475"
          />
          <path
            d="M66.1268 37.5444H66.1234C65.6885 36.9074 65.3529 36.1883 65.1439 35.5205L61.5241 27.1917L57.8563 17.38C57.8563 17.38 57.096 15.7225 56.8083 15.2568C53.0686 14.1095 51.8186 20.2191 51.8186 20.2191C52.1576 21.1506 60.0378 44.3732 66.185 46.7979C70.1371 48.3561 73.0652 46.8458 75.3118 44.1472C70.6953 44.4588 67.596 40.1848 66.1303 37.541L66.1268 37.5444Z"
            fill="#000080"
          />
          <path
            d="M56.8083 15.2294C55.4624 13.0513 53.6336 11.4999 51.3733 11.5992H51.3391C51.1439 11.6095 50.9487 11.6369 50.7501 11.6712C50.1542 11.7568 49.5788 11.9486 49.0377 12.2533C48.7398 12.404 48.1679 12.8492 48.1679 12.8492C47.3459 13.5239 46.6542 14.4143 46.2021 15.3766C42.298 23.7465 38.7603 41.839 30.4692 49.6883C33.1165 50.0376 35.8425 50.2944 38.637 50.4383C40.959 47.3527 42.7158 43.6232 44.0549 40.3424C44.0549 40.3424 44.2329 39.8835 44.5514 39.0718L51.9042 20.4554C51.9042 20.4554 54.5138 14.7431 56.8014 15.2328H56.8117L56.8083 15.2294Z"
            fill="#4A7D3C"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_8002_10824">
          <rect
            width="153.767"
            height="50"
            fill="white"
            transform="translate(0 0.640137)"
          />
        </clipPath>
        <clipPath id="clip1_8002_10824">
          <rect
            width="153.767"
            height="50"
            fill="white"
            transform="translate(0 0.640137)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
