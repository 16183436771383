import { ThemeNames } from "../../contexts/application/constants"
import animation from "../tokens/wps-advisory/animation.json"
import breakpoints from "../tokens/wps-advisory/breakpoints.json"
import buttons from "../tokens/wps-advisory/buttons.json"
import colors from "../tokens/wps-advisory/colors.json"
import iconsizing from "../tokens/wps-advisory/iconsizing.json"
import overlay from "../tokens/wps-advisory/overlay.json"
import radius from "../tokens/wps-advisory/radius.json"
import shadows from "../tokens/wps-advisory/shadows.json"
import styling from "../tokens/wps-advisory/styling.json"
import typeface from "../tokens/wps-advisory/typeface.json"
import typography from "../tokens/wps-advisory/typography.json"
import { TTheme } from "../types"

const tokens = {
  mode: ThemeNames.WPS_ADVISORY,
  animation,
  breakpoints,
  buttons,
  colors,
  iconsizing,
  overlay,
  radius,
  shadows,
  styling,
  typeface,
  typography,
} as TTheme
export default tokens
