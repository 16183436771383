import { lazy } from "react"
import { Route, Routes } from "react-router-dom"

import { PAGE_PATH } from "../../../../contexts/application/constants"
import LoginPage from "../../../../pages/LoginPage/LoginPage"
import { LogoutCaseManagerPage } from "../../../../pages/LogoutPage/LogoutCaseManagerPage"
import { LogoutPage } from "../../../../pages/LogoutPage/LogoutPage"
import RegisterPage from "../../../../pages/RegisterPage/RegisterPage"
import ResetPasswordPage from "../../../../pages/ResetPasswordPage/ResetPasswordPage"
import { ProtectedRoute } from "./ProtectedRoute"
import RequireAuth from "./RequireAuth"

const CoopUnauthorisedPage = lazy(
  () => import("../../../../pages/UnauthorisedPages/Coop/CoopUnauthorisedPage")
)

const NotificationsPage = lazy(
  () => import("../../../../pages/NotificationsPage/NotificationsPage")
)

const NotificationsPageCoop = lazy(
  () => import("../../../../pages/NotificationsPage/NotificationsPageCoop")
)

const ProfilePageCoop = lazy(
  () => import("../../../../pages/ProfilePage/ProfilePageCoop")
)

//Code Splitting for Probate Case Pages
const CaseDocumentsPage = lazy(
  () =>
    import(
      "../../../../pages/ProbateCasePages/CaseDocumentsPage/CaseDocumentsPage"
    )
)
const CaseDocumentDetailsPage = lazy(
  () =>
    import(
      "../../../../pages/ProbateCasePages/CaseDocumentDetailsPage/CaseDocumentDetailsPage"
    )
)
const CaseSharingPage = lazy(
  () =>
    import("../../../../pages/ProbateCasePages/CaseSharingPage/CaseSharingPage")
)
const CaseSharedWithMePage = lazy(
  () =>
    import(
      "../../../../pages/ProbateCasePages/CaseSharedWithMePage/CaseSharedWithMePage"
    )
)
const CaseSharedWithMeFileDetailsPage = lazy(
  () =>
    import(
      "../../../../pages/ProbateCasePages/CaseSharedWithMeFileDetailsPage/CaseSharedWithMeFileDetailsPage"
    )
)

const CaseSearchPage = lazy(
  () =>
    import("../../../../pages/ProbateCasePages/CaseSearchPage/CaseSearchPage")
)

const CaseManagerRequestPage = lazy(
  () =>
    import(
      "../../../../pages/ProbateCasePages/CaseManagerRequestPage/CaseManagerRequestPage"
    )
)
const CaseManagerHomePage = lazy(
  () =>
    import(
      "../../../../pages/ProbateCasePages/CaseManagerHomePage/CaseManagerHomePage"
    )
)
const CaseManagerAnalyticsPage = lazy(
  () =>
    import(
      "../../../../pages/ProbateCasePages/CaseManagerAnalyticsPage/CaseManagerAnalyticsPage"
    )
)
const ChooseProbateCasePage = lazy(
  () =>
    import(
      "../../../../pages/ProbateCasePages/ChooseProbateCasePage/ChooseProbateCasePage"
    )
)
const CaseDetailsPage = lazy(
  () =>
    import("../../../../pages/ProbateCasePages/CaseDetailsPage/CaseDetailsPage")
)
const AddSignaturePage = lazy(
  () =>
    import(
      "../../../../pages/ProbateCasePages/AddSignaturePage/AddSignaturePage"
    )
)
const CaseManagerDocumentSentPage = lazy(
  () =>
    import(
      "../../../../pages/ProbateCasePages/CaseManagerDocumentSentPage/CaseManagerDocumentSentPage"
    )
)
const CaseManagerDocumentTaggerIframePage = lazy(
  () =>
    import(
      "../../../../pages/ProbateCasePages/CaseManagerDocumentTaggerIframePage/CaseManagerDocumentTaggerIframePage"
    )
)

const CaseManagerSSOPage = lazy(
  () =>
    import(
      "../../../../pages/ProbateCasePages/CaseManagerSSOPage/CaseManagerSSOPage"
    )
)

export const ProbateCaseRoutes = ({
  shouldShowLoginPage,
  loginType,
  authLoading,
}: {
  shouldShowLoginPage: boolean
  loginType: string
  authLoading?: boolean
}) => {
  return (
    <Routes>
      {shouldShowLoginPage && (
        <>
          <Route path={PAGE_PATH.LoginPage} element={<LoginPage />} />
          <Route path={PAGE_PATH.RegisterPage} element={<RegisterPage />} />
          <Route
            path={PAGE_PATH.ResetPasswordPage}
            element={<ResetPasswordPage />}
          />
          <Route
            path={PAGE_PATH.CaseManagerSSOPage}
            element={<CaseManagerSSOPage />}
          />
        </>
      )}

      {/* Case tracker pages */}
      <Route element={<ProtectedRoute />}>
        <Route path={PAGE_PATH.Cases} element={<ChooseProbateCasePage />} />
        <Route path={PAGE_PATH.CaseDetailsPage} element={<CaseDetailsPage />} />
        <Route path={PAGE_PATH.CaseDocuments} element={<CaseDocumentsPage />} />
        <Route
          path={PAGE_PATH.CaseDocumentDetailsPage}
          element={<CaseDocumentDetailsPage />}
        />
        <Route path={PAGE_PATH.CaseProfilePage} element={<ProfilePageCoop />} />
        <Route
          path={PAGE_PATH.CaseNotificationsPage}
          element={<NotificationsPageCoop />}
        />
        <Route path={PAGE_PATH.CaseSharingPage} element={<CaseSharingPage />} />
        <Route
          path={PAGE_PATH.CaseSharedWithMePage}
          element={<CaseSharedWithMePage />}
        />
        <Route
          path={PAGE_PATH.CaseSharedWithMeFileDetailsPage}
          element={<CaseSharedWithMeFileDetailsPage />}
        />
        <Route
          path={PAGE_PATH.CaseDocumentAddSignature}
          element={<AddSignaturePage />}
        />
        <Route
          path={PAGE_PATH.NotificationsPage}
          element={<NotificationsPage />}
        />
        <Route path={PAGE_PATH.CaseSearchPage} element={<CaseSearchPage />} />

        {/* Case manager pages */}
        <Route
          path={PAGE_PATH.CaseManagerHomePage}
          element={<CaseManagerHomePage />}
        />
        <Route
          path={PAGE_PATH.CaseManagerAnalyticsPage}
          element={<CaseManagerAnalyticsPage />}
        />
        <Route
          path={PAGE_PATH.CaseManagerDocumentRequestPage}
          element={<CaseManagerRequestPage />}
        />
        <Route
          path={PAGE_PATH.CaseManagerDocumentTaggerIframePage}
          element={<CaseManagerDocumentTaggerIframePage />}
        />
        <Route
          path={PAGE_PATH.CaseManagerDocumentSentPage}
          element={<CaseManagerDocumentSentPage />}
        />

        {/* This LogoutCaseManagerPage route is needed for clearing out keys from local storage for COOP case handler we might need a Logout link as well in the UI */}
        <Route
          path={PAGE_PATH.CaseManagerLogout}
          element={<LogoutCaseManagerPage />}
        />

        {/* Case Contact user pages */}
        <Route
          path={PAGE_PATH.CaseContactSharedWithMePage}
          element={<CaseSharedWithMePage />}
        />
        <Route
          path={PAGE_PATH.CaseContactFileDetailsPage}
          element={<CaseSharedWithMeFileDetailsPage />}
        />
        <Route path={PAGE_PATH.ProfilePage} element={<ProfilePageCoop />} />

        <Route path={PAGE_PATH.Logout} element={<LogoutPage />} />
      </Route>

      <Route
        path={PAGE_PATH.UnauthorisedPage}
        element={<CoopUnauthorisedPage />}
      />
      <Route
        path="*"
        element={
          <RequireAuth
            loginType={loginType}
            showLoginPage={shouldShowLoginPage}
            isLoading={authLoading}
          />
        }
      />
    </Routes>
  )
}
