import classNames from "classnames"
import { useRef, useState } from "react"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"
import { useOutsideAlerter } from "../../../utils/outsideClickAlerter"
import { ValidationErrorLabel } from "../ValidationErrorLabel/ValidationErrorLabel"
import "./BrowserDefaultSelect.css"

export interface BrowserDefaultSelectItem {
  name: string
  value: string
  disabled?: boolean
  uniqueId?: string
  requestId?: string
  stageId?: string
  selected?: boolean
}

export interface BrowserDefaultSelectProps {
  options: BrowserDefaultSelectItem[]
  label?: string
  name: string
  className?: string
  isRequired?: boolean
  error?: {
    hasError: boolean
    message: string
  }
  onChange: (value: string) => void
  children?: React.ReactNode
  shouldShowErrorIcon?: boolean
  ariaLabel?: string
  ariaLabelledBy?: string
}

export const BrowserDefaultSelect: React.FC<BrowserDefaultSelectProps> = ({
  options,
  label,
  name,
  className = "",
  isRequired,
  error,
  onChange,
  children,
  shouldShowErrorIcon = false,
  ariaLabel,
  ariaLabelledBy,
}) => {
  const wrapperRef = useRef(null)

  const [open, setOpen] = useState(false)
  useOutsideAlerter(wrapperRef, setOpen)

  const dataId = label && label?.toLowerCase().trim().replace(/ /g, "-")

  const IconChevronUp = useGetIcon("IconChevronUp")
  const IconChevronDown = useGetIcon("IconChevronDown")

  const BrowserDefaultSelectContainerClass = classNames(
    "browser-default-select-container",
    className
  )

  const selectLabelClass = classNames("select-label", {
    required: isRequired,
  })

  const browserDefaultSelectClass = classNames("browser-default-select", {
    error: error && error.hasError,
  })

  return (
    <div className={BrowserDefaultSelectContainerClass}>
      {label && (
        <label htmlFor={dataId} className={selectLabelClass}>
          {label}
        </label>
      )}
      {error?.hasError && (
        <ValidationErrorLabel
          id="input-error"
          errorContent={error?.message ?? ""}
          visible={error?.hasError}
          shouldShowWarningIcon={shouldShowErrorIcon}
          hasMarginTop={false}
        />
      )}
      <div className="browser-default-select-wrapper">
        <select
          id={dataId}
          key={dataId}
          className={browserDefaultSelectClass}
          name={name}
          ref={wrapperRef}
          onChange={(event) => onChange(event.target.value)}
          value={options.find((option) => option.selected)?.value}
          onClick={(e) => {
            e.preventDefault()
            setOpen(!open)
          }}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          aria-invalid={error?.hasError}
          aria-required={isRequired}
        >
          {options.map((option: BrowserDefaultSelectItem, index: number) => (
            <option value={option.value} key={index} disabled={option.disabled}>
              {option.name}
            </option>
          ))}
        </select>
        <span
          className="open-close-svg"
          onClick={() => {
            setOpen(!open)
          }}
        >
          {open ? IconChevronUp : IconChevronDown}
        </span>
        {children}
      </div>
    </div>
  )
}
