import { lazy } from "react"
import { Route, Routes } from "react-router-dom"

import { PAGE_PATH } from "../../../../contexts/application/constants"
import ErrorPage from "../../../../pages/ErrorPage/ErrorPage"
import { Redirect } from "../Redirect"

const AccountDocumentLibraryPage = lazy(
  () =>
    import(
      "../../../../pages/Communications/AccountDocumentLibraryPage/AccountDocumentLibraryPage"
    )
)

export const DocumentLibraryRoutes = () => {
  return (
    <Routes>
      <Route
        path={PAGE_PATH.AccountDocumentLibraryPage}
        element={<AccountDocumentLibraryPage />}
      />
      <Route path={PAGE_PATH.ErrorPage} element={<ErrorPage />} />
      <Route path="*" element={<Redirect />} />
    </Routes>
  )
}
