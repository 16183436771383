import { lazy } from "react"
import { Route, Routes } from "react-router-dom"

import { IUser } from "../../../../api/api-client/api-types"
import {
  PAGE_PATH,
  UserModuleType,
} from "../../../../contexts/application/constants"
import { DocusignRedirectPage } from "../../../../pages/DocusignRedirectPage/DocusignRedirectPage"
import { hasModules } from "../../utils/utils"
import { Redirect } from "../Redirect"

const HomePage = lazy(() => import("../../../../pages/Homepage/HomePage"))
const ThemePage = lazy(() => import("../../../../pages/ThemePage/ThemePage"))
const SharingPage = lazy(() => import("../../../../pages/Sharing/SharingPage"))
const SearchResultPage = lazy(
  () => import("../../../../pages/SearchResultPage/SearchResultPage")
)
const FolderPage = lazy(() => import("../../../../pages/FolderPage/FolderPage"))
const SharedWithMePage = lazy(
  () => import("../../../../pages/SharedWithMePage/SharedWithMePage")
)
const NotificationsPage = lazy(
  () => import("../../../../pages/NotificationsPage/NotificationsPage")
)

const RecentActivityPage = lazy(
  () => import("../../../../pages/RecentActivityPage/RecentActivityPage")
)
const ProfilePage = lazy(
  () => import("../../../../pages/ProfilePage/ProfilePage")
)
const DocusignRequestPage = lazy(
  () => import("../../../../pages/DocusignRequestPage/DocusignRequestPage")
)
const DocumentRequestPage = lazy(
  () => import("../../../../pages/DocumentRequestPage/DocumentRequestPage")
)
const FileDetailsPage = lazy(
  () => import("../../../../pages/FileDetailsPage/FileDetailsPage")
)
const DocusignRequestIframePage = lazy(
  () =>
    import(
      "../../../../pages/DocusignRequestIframePage/DocusignRequestIframePage"
    )
)
const DocusignRequestSentPage = lazy(
  () =>
    import("../../../../pages/DocusignRequestSentPage/DocusignRequestSentPage")
)
const ClientsPage = lazy(
  () => import("../../../../pages/ClientsPage/ClientsPage")
)
const AddSignaturePage = lazy(
  () =>
    import(
      "../../../../pages/ProbateCasePages/AddSignaturePage/AddSignaturePage"
    )
)
const ErrorPage = lazy(() => import("../../../../pages/ErrorPage/ErrorPage"))

export const VaultRoutes = ({ currentUser }: { currentUser: IUser }) => {
  return (
    <Routes>
      {hasModules([UserModuleType.VAULT], currentUser) &&
        !hasModules(
          [
            UserModuleType.DOCUMENT_HUB,
            UserModuleType.DOCUMENTS_LIBRARY_ADVISER_VIEW,
            UserModuleType.DOCUMENT_LIBRARY,
          ],
          currentUser
        ) && (
          <>
            <Route path={PAGE_PATH.HomePage} element={<HomePage />} />
            <Route path={PAGE_PATH.FolderPage} element={<FolderPage />} />
          </>
        )}
      {hasModules([UserModuleType.SHARING], currentUser) && (
        <Route path={PAGE_PATH.SharingPage} element={<SharingPage />} />
      )}
      {hasModules(
        [
          UserModuleType.SHARED_WITH_ME_LIST,
          UserModuleType.SHARED_WITH_ME_VIEW,
          UserModuleType.SHARED_WITH_ME_EDIT,
        ],
        currentUser
      ) &&
        !hasModules([UserModuleType.CLIENT_LIST], currentUser) && (
          <Route
            path={PAGE_PATH.SharedWithMePage}
            element={<SharedWithMePage />}
          />
        )}
      {!hasModules([UserModuleType.ORGANISATIONS_ADMIN], currentUser) &&
        hasModules([UserModuleType.CLIENT_LIST], currentUser) && (
          <Route path={PAGE_PATH.ClientsPage} element={<ClientsPage />} />
        )}
      {hasModules([UserModuleType.SEARCH], currentUser) && (
        <Route
          path={PAGE_PATH.SearchResultPage}
          element={<SearchResultPage />}
        />
      )}
      {hasModules([UserModuleType.AUDIT_LOG], currentUser) && (
        <Route
          path={PAGE_PATH.RecentActivityPage}
          element={<RecentActivityPage />}
        />
      )}
      {hasModules([UserModuleType.NOTIFICATIONS], currentUser) && (
        <Route
          path={PAGE_PATH.NotificationsPage}
          element={<NotificationsPage />}
        />
      )}
      <Route path={PAGE_PATH.ProfilePage} element={<ProfilePage />} />
      {hasModules(
        [UserModuleType.VAULT_DOCUMENT_UPLOAD_FOR_SIGNING],
        currentUser
      ) && (
        <>
          <Route
            path={PAGE_PATH.DocusignRequestPage}
            element={<DocusignRequestPage />}
          />
          <Route
            path={PAGE_PATH.DocusignRequestSentPage}
            element={<DocusignRequestSentPage />}
          />
          <Route
            path={PAGE_PATH.DocusignRequestIframePage}
            element={<DocusignRequestIframePage />}
          />
          <Route
            path={PAGE_PATH.DocuSignAddSignature}
            element={<AddSignaturePage />}
          />
        </>
      )}
      {/* This route is used to close the docusign tab after the user has signed the document no user authentication or interaction is required as the page only closes itself */}
      <Route
        path={PAGE_PATH.DocusignRedirect}
        element={<DocusignRedirectPage />}
      />

      {hasModules([UserModuleType.FLAT_FILE_LIST], currentUser) &&
        currentUser?.modules?.includes(UserModuleType.CLIENT_LIST) && (
          <>
            <Route path={PAGE_PATH.ClientsPage} element={<ClientsPage />} />
          </>
        )}
      {hasModules([UserModuleType.FLAT_FILE_LIST], currentUser) &&
        currentUser?.modules?.includes(UserModuleType.DOCUMENT_REQUEST) &&
        !currentUser?.modules?.includes(UserModuleType.CLIENT_LIST) &&
        !hasModules(
          [
            UserModuleType.DOCUMENT_HUB,
            UserModuleType.DOCUMENTS_LIBRARY_ADVISER_VIEW,
            UserModuleType.DOCUMENT_LIBRARY,
          ],
          currentUser
        ) && (
          <>
            <Route path={PAGE_PATH.HomePage} element={<HomePage />} />
            <Route
              path={PAGE_PATH.FileDetailsPage}
              element={<FileDetailsPage />}
            />
          </>
        )}
      {hasModules([UserModuleType.DOCUMENT_REQUEST], currentUser) && (
        <>
          <Route
            path={PAGE_PATH.DocumentRequestPage}
            element={<DocumentRequestPage />}
          />
        </>
      )}
      <Route path={PAGE_PATH.ThemePage} element={<ThemePage />} />
      <Route path={PAGE_PATH.ErrorPage} element={<ErrorPage />} />
      <Route path="*" element={<Redirect />} />
    </Routes>
  )
}
