import { FC, useState } from "react"

import { IFile } from "../../../../../../api/api-client/api-types"
import { useGetIcon } from "../../../../../../styled-components/GetIconLibraryInTheme"
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from "../../../../../atoms/Button"
import { LoadingSpinner } from "../../../../../atoms/LoadingSpinner/LoadingSpinner"
import {
  Thumbnail,
  ThumbnailSize,
} from "../../../../../atoms/Thumbnail/Thumbnail"

import "./DisplayDocument.scss"

interface DisplayDocumentProps {
  file: IFile
  removeFile: (id: string) => void
  isRemovingFile?: boolean
  shouldDragAndDrop?: boolean
}

export const DisplayDocument: FC<DisplayDocumentProps> = ({
  file,
  removeFile,
  isRemovingFile,
  shouldDragAndDrop = false,
}) => {
  const [shouldShowDeleteConfirmation, setShouldShowDeleteConfirmation] =
    useState(false)
  const errorIcon = useGetIcon("ErrorIcon")
  const dragIcon = useGetIcon("DragAndDrop")

  if (isRemovingFile) {
    return (
      <div className="flex justify-content-center">
        <LoadingSpinner size="24px" />
      </div>
    )
  }

  return (
    <div id={file.id} className="display-document" tabIndex={0}>
      <div className="display-document-container">
        <Button
          ariaLabel="delete button"
          onClick={() => setShouldShowDeleteConfirmation(true)}
        >
          {errorIcon}
        </Button>
        <Thumbnail
          fileExtension={file.extension}
          imageString={file.base64StringThumbnail}
          size={ThumbnailSize.MEDIUM1}
        />
        <div className="display-document-file-name">
          {file.name}
        </div>
        {shouldDragAndDrop && (
          <div className="display-document-drag-and-drop">{dragIcon}</div>
        )}
      </div>
      {shouldShowDeleteConfirmation && (
        <div className="display-document-is-deleting">
          <span className="display-document-is-deleting-text">
            Are you sure you want to delete this file?
          </span>
          <div>
            <Button
              className="display-document-is-deleting-primary-button"
              variant={BUTTON_VARIANT.SECONDARY}
              onClick={() => removeFile(file.id)}
              size={BUTTON_SIZE.SMALL}
            >
              Confirm
            </Button>
            <Button
              variant={BUTTON_VARIANT.DANGER_PRIMARY}
              onClick={() => setShouldShowDeleteConfirmation(false)}
              size={BUTTON_SIZE.SMALL}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
      <hr />
    </div>
  )
}
