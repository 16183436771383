import { ThemeNames } from "../../../contexts/application/constants"
import { TFEFooterLogo } from "../../../design-tokens/imgs/TFEFooter"
import AmadeusLogo from "../../../design-tokens/imgs/amadeus/mainLogo"
import { FCLogo } from "../../../design-tokens/imgs/fortCirrus/mainLogo"
import { LifestageLogo } from "../../../design-tokens/imgs/lifestage/lifestageLogo"
import { OneLegacyLogo } from "../../../design-tokens/imgs/oneLegacy/legacyLogo"
import { SchofieldSweeneyLogo } from "../../../design-tokens/imgs/schofieldSweeney/schofieldSweeneyLogo"
import { WpsAdvisoryLogo } from "../../../design-tokens/imgs/wpsAdvisory/wpsAdvisoryLogo"
import LegadoLogo, { LogoVariant } from "../../../images/LegadoLogo"
import { TLeftBarNavigationData } from "./FoldersSharingNavigationItems"

export const InitialNavigationData: TLeftBarNavigationData = {
  level1: "",
  level2: "",
  level3: "",
}

export const themesWithoutLogoText = [
  "THE_FAMILY_ELEPHANT",
  "FORT_CIRRUS",
  "ONE_LEGACY",
  "AMADEUS",
  ThemeNames.LIFESTAGE,
  ThemeNames.SCHOFIELD_SWEENEY,
  ThemeNames.WPS_ADVISORY,
]

const themeMap: { [key: string]: () => JSX.Element } = {
  THE_FAMILY_ELEPHANT: TFEFooterLogo,
  FORT_CIRRUS: FCLogo,
  ONE_LEGACY: OneLegacyLogo,
  AMADEUS: AmadeusLogo,
  LIFESTAGE: LifestageLogo,
  SCHOFIELD_SWEENEY: SchofieldSweeneyLogo,
  WPS_ADVISORY: WpsAdvisoryLogo,
}
// Render the component based on the selected theme
// If the theme is not found in the map, render LegadoLogo by default
export const renderThemeLogo = (selectedTheme: string) => {
  const Component =
    themeMap[selectedTheme] ||
    (() => <LegadoLogo variant={LogoVariant.WITH_NAME_DEFAULT} />)
  return <Component />
}
