export function checkForParamInUrlSearchParams(
  params: URLSearchParams,
  paramName: string
) {
  const value = params.get(paramName.toLowerCase()) || params.get(paramName)
  if (value) {
    return value
  }
  return undefined
}
