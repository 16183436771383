import { AxiosError } from "axios"
import { ApiController } from "../apiController"

/**
 * Handle error for API requests and prompt a second attempt if needed
 * @param error - The error object
 * @param errorMessage - The error message
 * @param secondAttempt - Whether it's the second attempt
 * @returns Whether the request should be retried
 */
export const handleErrorAndTokenRefresh = async (
  error: AxiosError,
  errorMessage: string,
  secondAttempt: boolean = false
): Promise<boolean> => {
  const api = ApiController.getInstance()
  try {
    if (error?.response?.status === 401 && !secondAttempt) {
      return await api.doTokenRefresh()
    } else if (error?.response?.status === 401 && secondAttempt) {
      api.handleTimeout(true)
      return false
    }
  } catch (error) {
    console.error(error)
  }
  console.log(errorMessage)
  console.error(error)
  return false
}
