import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { IUser } from "../../api/api-client/api-types"
import { setSelectedUserSharingWithMe } from "../../contexts/application/action"
import { PAGE_PATH, UserModuleType } from "../../contexts/application/constants"
import { useApplicationContext } from "../../contexts/application/context"
import { usePlatformContext } from "../../contexts/platform/platformContextProvider"
import {
  setDisplaySuccessOrErrorMessage,
  useToastContext,
} from "../../contexts/toasts"
import { useUserContext } from "../../contexts/users"
import { notificationMessages } from "../../data/notificationMessages"
import { useScrollToTopOnChange } from "../../hooks/useScrollToTopOnChange"
import { loginTypes } from "../../utils/auth/loginTypes"
import { DocumentHubRoutes } from "./components/DocumentHubRoutes/DocumentHubRoutes"
import { DocumentLibraryRoutes } from "./components/DocumentLibraryRoutes/DocumentLibraryRoutes"
import { ProbateCaseRoutes } from "./components/ProbateCaseRoutes/ProbateCaseRoutes"
import { VaultRoutes } from "./components/VaultRoutes/VaultRoutes"
import { hasModules } from "./utils/utils"

interface AllRoutesProps {
  basePath?: string
}

export const AllRoutes = ({ basePath }: AllRoutesProps) => {
  const {
    applicationState: { usersSharingWithMe, authLoading },
    dispatch,
  } = useApplicationContext()
  const {
    userState: { currentUser },
  } = useUserContext()
  const { dispatch: toastDispatch } = useToastContext()

  const { loginType } = usePlatformContext()

  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  useScrollToTopOnChange(pathname)

  const searchParams = Object.fromEntries(new URLSearchParams(search).entries())

  // This useEffect is here to deal with the case where the user is redirected to the shared with me page
  useEffect(() => {
    if (
      !currentUser ||
      !search ||
      !usersSharingWithMe ||
      usersSharingWithMe.length === 0
    )
      return

    if (searchParams.client) {
      const selectedUser = usersSharingWithMe.find(
        (user: IUser) => user.email === searchParams.client
      )

      if (selectedUser) {
        dispatch(setSelectedUserSharingWithMe(selectedUser.userId))
      } else {
        toastDispatch(
          setDisplaySuccessOrErrorMessage({
            messageType: "ERROR",
            message: notificationMessages.invalidUser.ERROR,
          })
        )
      }
      navigate(PAGE_PATH.SharedWithMePage)
    }
  }, [
    currentUser,
    dispatch,
    navigate,
    search,
    searchParams,
    toastDispatch,
    usersSharingWithMe,
  ])

  const shouldShowLoginPage = loginType === loginTypes.PASSWORD

  if (!currentUser || (currentUser && shouldShowLoginPage)) {
    return (
      <ProbateCaseRoutes
        shouldShowLoginPage={shouldShowLoginPage}
        loginType={loginType}
        authLoading={authLoading}
      />
    )
  }

  //fnz specific Adviser,PowerAdviser,CorporateAdmin with DOCUMENTS_LIBRARY_ADVISER_VIEW feature and with context search param
  // or clients (primary users) with DOCUMENT_LIBRARY feature
  //context value for fnz api:
  //It can be for an account ID or a person Id
  //context=account:001-0000000646 or context=015-0000000646
  if (
    (hasModules([UserModuleType.DOCUMENTS_LIBRARY_ADVISER_VIEW], currentUser) &&
      searchParams.context) ||
    hasModules([UserModuleType.DOCUMENT_LIBRARY], currentUser)
  ) {
    return <DocumentLibraryRoutes />
  }

  if (
    hasModules(
      [
        UserModuleType.DOCUMENT_HUB,
        UserModuleType.DOCUMENTS_LIBRARY_ADVISER_VIEW,
        UserModuleType.DOCUMENT_LIBRARY,
      ],
      currentUser
    ) &&
    !searchParams.context
  ) {
    return <DocumentHubRoutes currentUser={currentUser} basePath={basePath} />
  }

  return <VaultRoutes currentUser={currentUser} />
}
