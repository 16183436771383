// TODO: remove this once we have the correct types
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SuggestedFolderDto } from "legado-generated-api-client/models"
import suggestedFolders from "../staticData/getSuggestedFolders.json"

type TGetSuggestedFolders = () => SuggestedFolderDto[]

const isSuggestedFolderDtos = (folders: any) => {
  if ((folders as SuggestedFolderDto[])?.length) {
    return folders as SuggestedFolderDto[]
  }
}

export const getSuggestedFoldersFromLocalData: TGetSuggestedFolders = () => {
  return isSuggestedFolderDtos(suggestedFolders) || []
}
