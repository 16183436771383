import classNames from "classnames"
import { debounce } from "lodash"
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react"
import { setSearchExpression, useSearchContext } from "../../../contexts/search"
import Search from "../../../design-tokens/icons/theme/oneX/Search"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"
import "./SearchBarWithContext.scss"

// Stable debounced function created outside component so it isn't created on each render
const debouncedFn = debounce(
  // dynamic time and to helper file?
  (callback: (value: string) => void, value: string) => {
    callback(value)
  },
  500
)

export interface SearchBarWithContextProps {
  onSubmit?: (searchExpression?: string) => void
  onClearSearch?: () => void
  enableSearchContext?: boolean
  label?: string
  className?: string
  shouldDisplaySearchButton?: boolean
  shouldDisplaySearchIconInInput?: boolean
}

const SearchBarWithContext: FC<SearchBarWithContextProps> = ({
  onSubmit,
  onClearSearch,
  enableSearchContext = false,
  label,
  className,
  shouldDisplaySearchButton = true,
  shouldDisplaySearchIconInInput = false,
}) => {
  //TODO: remove the label in search context or refactor this: https://dev.azure.com/secure-the-file/Application/_workitems/edit/17729
  const {
    searchState: { label: contextLabel, searchExpression },
    dispatch,
  } = useSearchContext()
  const [search, setSearch] = useState("")
  const crossIcon = useGetIcon("Cross")
  const pageContentSearchClassNames = classNames("page-content-search-bar", {
    [`${className}`]: className,
  })
  const searchInputClassNames = classNames("search-input", {
    "search-input-with-icon": shouldDisplaySearchIconInInput,
  })
  const crossIconClassNames = classNames("crossIcon", {
    "search-input-with-button": shouldDisplaySearchButton,
  })

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      if (enableSearchContext) {
        dispatch(setSearchExpression(value))
      } else {
        setSearch(value)
      }
      if (onSubmit) {
        debouncedFn(onSubmit, value)
      }
    },
    [dispatch, enableSearchContext, onSubmit]
  )

  useEffect(() => {
    return () => {
      // canceling debounce ensures we do not process outdated requests
      debouncedFn.cancel()
    }
  }, [])

  return (
    <div className={pageContentSearchClassNames} role="search">
      <input
        type="text"
        placeholder={label ?? contextLabel}
        className={searchInputClassNames}
        aria-label={label ?? contextLabel}
        role="searchbox"
        value={enableSearchContext ? searchExpression : search}
        onChange={handleChange}
        autoFocus
      />
      {shouldDisplaySearchIconInInput && (
        <div className="search-icon-container">
          <Search />
        </div>
      )}
      {(searchExpression || search) && (
        <button
          className={crossIconClassNames}
          title="Clear search"
          aria-label="Clear search"
          type="button"
          tabIndex={0}
          onClick={() => {
            if (enableSearchContext) {
              dispatch(setSearchExpression(""))
            } else {
              setSearch("")
            }
            if (onClearSearch) {
              onClearSearch()
            }
          }}
        >
          {crossIcon}
        </button>
      )}
      {shouldDisplaySearchButton && (
        <button
          className="search-bar-button"
          aria-label="Search button"
          onClick={() => onSubmit?.(search)}
        >
          <Search />
        </button>
      )}
    </div>
  )
}

export default SearchBarWithContext
