// TODO: remove this once we have the correct types
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from "react"

import { ApiConnection } from "../../../api/api-client/common/ApiConnection"
import { IThumbnailAction } from "../../../contexts/thumbnails/reducer"
import { IToastAction } from "../../../contexts/toasts/reducer"
import { connectToApiAndReturnUser } from "./connectToApiAndReturnUser"

export const connectToApiForTesting = (
  emailForTesting: string,
  dispatch: (value: any) => void,
  dispatchUser: (value: any) => void,
  thumbnailDispatch: (value: IThumbnailAction) => void,
  toastDispatch: Dispatch<IToastAction>,
  token?: string,
  apiKeyFromUrl?: string,
  themeFromUrl?: string
) => {
  // We connect to the API here, as we have the email and token but for testing purposes.
  const apiConnection = new ApiConnection(apiKeyFromUrl, emailForTesting)

  connectToApiAndReturnUser({
    apiConnection,
    token,
    dispatch,
    dispatchUser,
    thumbnailDispatch,
    toastDispatch,
    themeFromUrl,
    // GA tracking id could be blank for testing
    gaTrackingId: "",
  })
}
