import { FC } from "react"

const IconChevronLeftSmall: FC = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8125 14.3594L6.85169 9.53538C6.81946 9.50407 6.79388 9.46689 6.77643 9.42597C6.75898 9.38505 6.75 9.34118 6.75 9.29688C6.75 9.25257 6.75898 9.2087 6.77643 9.16778C6.79388 9.12686 6.81946 9.08968 6.85169 9.05838L11.8125 4.23438"
      stroke="#444444"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default IconChevronLeftSmall
