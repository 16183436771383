import { FC } from "react"

const IconChevronRightSmall: FC = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 4.23438L11.7108 9.05837C11.743 9.08968 11.7686 9.12686 11.7861 9.16778C11.8035 9.2087 11.8125 9.25257 11.8125 9.29687C11.8125 9.34118 11.8035 9.38505 11.7861 9.42597C11.7686 9.46689 11.743 9.50407 11.7108 9.53537L6.75 14.3594"
      stroke="#444444"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default IconChevronRightSmall
