import { IFolder, IUser } from "../../../api/api-client/api-types"
import {
  PAGE_PATH,
  UserModuleType,
} from "../../../contexts/application/constants"
import { useApplicationContext } from "../../../contexts/application/context"
import { INavLink } from "../LeftNavigationBar"
import { leftNavMenuItems } from "../LeftNavigationData"
import { TLeftBarNavigationData } from "./FoldersSharingNavigationItems"
import { InitialNavigationData } from "./utils"

export const GetMenuItemsFromUserModules = ({
  allMenuItems,
  user,
}: {
  allMenuItems: INavLink[]
  user?: IUser
}) => {
  const {
    applicationState: { usersSharingWithMe, selectedTheme },
  } = useApplicationContext()
  const hasUsersSharing = usersSharingWithMe.length > 0

  if (!user || user.modules?.length === 0) return []
  return allMenuItems.filter((menuItem: INavLink) => {
    /* We don't want to necessarily disable the ClientSearchInDocHub feature for ONE-X as there's some functionality
    as part of that feature we're still wanting to keep for the demo. We just want to hide the tab */
    if (
      selectedTheme === "ONE_X" &&
      menuItem.href === PAGE_PATH.CommunicationsSearchPage
    ) {
      return false
    }
    switch (true) {
      case menuItem.href === PAGE_PATH.HomePage:
        return (
          user.modules?.includes(UserModuleType.VAULT) ||
          (user.modules?.includes(UserModuleType.FLAT_FILE_LIST) &&
            user.modules?.includes(UserModuleType.DOCUMENT_REQUEST) &&
            !user.modules.includes(UserModuleType.CLIENT_LIST) &&
            !user.modules?.includes(UserModuleType.DOCUMENT_HUB) &&
            !user.modules?.includes(UserModuleType.DOCUMENT_LIBRARY))
        )
      case menuItem.href === PAGE_PATH.HomePage ||
        menuItem.href === PAGE_PATH.FolderPage ||
        menuItem.name === "Files":
        return user.modules?.includes(UserModuleType.VAULT)

      case menuItem.href === PAGE_PATH.SharingPage ||
        menuItem.name === "Sharing":
        return (
          (user.modules?.includes(UserModuleType.SHARING) ||
            (user.modules?.includes(UserModuleType.SHARED_WITH_ME_LIST) &&
              hasUsersSharing)) &&
          !user.modules.includes(UserModuleType.CLIENT_LIST)
        )

      case (user.modules?.includes(UserModuleType.CLIENT_SEARCH_IN_DOC_HUB) &&
        menuItem.href === PAGE_PATH.ClientsPage) ||
        menuItem.name === "Clients":
        return (
          user.modules?.includes(UserModuleType.CLIENT_LIST) &&
          !user.modules?.includes(UserModuleType.ORGANISATIONS_ADMIN)
        )

      case menuItem.href === PAGE_PATH.CommunicationsSearchPage:
        return user.modules?.includes(UserModuleType.CLIENT_SEARCH_IN_DOC_HUB)

      case menuItem.href === PAGE_PATH.DocumentHubPage ||
        menuItem.href === PAGE_PATH.AnalyticsPage:
        return user.modules?.includes(UserModuleType.DOCUMENT_HUB)

      case menuItem.href === PAGE_PATH.Organisations:
        return user.modules?.includes(UserModuleType.ORGANISATIONS_ADMIN)

      case menuItem.href === PAGE_PATH.RecentActivityPage:
        return user.modules?.includes(UserModuleType.AUDIT_LOG)

      default:
        return true
    }
  })
}

export const getTopNavMenuItems = ({
  expandedLinks,
  myFilesSubLinks,
  navigate,
  pathname,
  setExpandedLinks,
  setIsMenuBarExpanded,
  sharingSubLinks,
  user,
  isMobileOrTablet,
}: {
  expandedLinks: TLeftBarNavigationData
  myFilesSubLinks: INavLink[]
  navigate: (path: string) => void
  pathname: string
  setExpandedLinks: (linkData: TLeftBarNavigationData) => void
  setIsMenuBarExpanded: (open: boolean) => void
  sharingSubLinks: INavLink[]
  user?: IUser
  isMobileOrTablet: boolean
}) => {
  const menuItems = GetMenuItemsFromUserModules({
    allMenuItems: leftNavMenuItems,
    user,
  })
  return menuItems.map((menuItem) => {
    const hasChildren = menuItem.name === "Sharing" || menuItem.name === "Files"
    const onClick = () => {
      isMobileOrTablet && setIsMenuBarExpanded(!menuItem.href)

      if (expandedLinks.level1 !== menuItem.name) {
        setExpandedLinks({ level1: menuItem.name, level2: "", level3: "" })
      } else {
        setExpandedLinks(InitialNavigationData)
      }

      if (menuItem.href) {
        navigate(menuItem.href!)
      }
    }

    switch (menuItem.name) {
      case "Sharing":
        return {
          ...menuItem,
          isActiveLink:
            expandedLinks.level1 === menuItem.name ||
            pathname === PAGE_PATH.SharingPage ||
            pathname === PAGE_PATH.SharedWithMePage,
          onClick,
          ...(hasChildren && {
            subLinks: sharingSubLinks,
          }),
        }
      case "Organisations":
        return {
          ...menuItem,
          isActiveLink:
            expandedLinks.level1 === menuItem.name ||
            pathname === PAGE_PATH.Organisations ||
            pathname === PAGE_PATH.OrganisationClients,
          onClick,
          ...(hasChildren && {
            subLinks: myFilesSubLinks,
          }),
        }
      default:
        return {
          ...menuItem,
          isActiveLink:
            expandedLinks.level1 === menuItem.name ||
            pathname === menuItem.href,
          onClick,
          ...(hasChildren && {
            subLinks: myFilesSubLinks,
          }),
        }
    }
  })
}

export const UpdateExpandedLinksWhenNavigating = ({
  folderId,
  folders,
  pathname,
}: {
  folderId?: string
  folders: IFolder[]
  pathname?: string
}) => {
  let newExpandedLinks = InitialNavigationData

  if (folderId) {
    const folderSelected = folders
      .map((x) => {
        if (x.id === folderId) {
          return x
        } else if (x.childFolders) {
          return x.childFolders.find((f) => f.id === folderId)
        }
        return undefined
      })
      .filter((x) => x !== undefined)[0]

    const parentFolder = folders.find((x) =>
      x.childFolders?.find((f) => f.id === folderId)
    )

    if (folderSelected) {
      newExpandedLinks = {
        level1: "Files",
        level2:
          folderSelected.level === 0
            ? folderSelected.name
            : parentFolder?.name ?? "",
        level3: folderSelected.level === 1 ? folderSelected?.name : "",
      }
      return newExpandedLinks
    }
  }

  if (pathname) {
    //shared sublinks handling
    const inSharingPage = pathname === PAGE_PATH.SharingPage
    const inSharedWithMePage = pathname === PAGE_PATH.SharedWithMePage

    if (inSharingPage || inSharedWithMePage) {
      return {
        level1: "Sharing",
        level2: inSharingPage ? "Contacts" : inSharedWithMePage ? "Shared" : "",
        level3: "",
      }
    }

    //first level links handling
    const pathLink = leftNavMenuItems.find((x) => x.href === pathname)

    if (pathLink) {
      return { ...newExpandedLinks, level1: pathLink.name }
    }
  }

  return newExpandedLinks
}
