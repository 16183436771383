import { ThemeNames } from "../contexts/application/constants"
import { useApplicationContext } from "../contexts/application/context"
import abrdnAndSubsidiariesIcons from "../design-tokens/icons/theme/abrdnAndSubsidiaries/GetIcon"
import avivaIcons from "../design-tokens/icons/theme/aviva/GetIcon"
import coopIcons from "../design-tokens/icons/theme/coop/GetIcon"
import fortCirrusIcons from "../design-tokens/icons/theme/fortcirrus/GetIcon"
import jamesHayIcons from "../design-tokens/icons/theme/jamesHay/GetIcon"
import legadoIcons from "../design-tokens/icons/theme/legado/GetIcon"
import oneXIconLibrary from "../design-tokens/icons/theme/oneX/GetIcon"
import schofiledSweeneyIcons from "../design-tokens/icons/theme/schofieldSweeney/GetIcon"
import scottishWidowsIcons from "../design-tokens/icons/theme/scottishWidows/GetIcon"

export const useGetIcon = (name?: string) => {
  const {
    applicationState: { selectedTheme },
  } = useApplicationContext()
  if (!name) {
    return null
  }
  switch (selectedTheme) {
    case "SCOTTISH_WIDOWS":
      const themedIcon = scottishWidowsIcons[name]
      return themedIcon || oneXIconLibrary[name] || oneXIconLibrary["Folder"]

    case "AVIVA":
      const themedIconAviva = avivaIcons[name]
      return (
        themedIconAviva || oneXIconLibrary[name] || oneXIconLibrary["Folder"]
      )

    case ThemeNames.ABRDN_D2C:
    case ThemeNames.ABRDN_VANILLA:
    case ThemeNames.ABRDN_BALMORAL:
    case ThemeNames.ABRDN_CAPITAL:
    case ThemeNames.ABRDN_LUDLOW:
    case ThemeNames.ABRDN_GIBBS:
    case ThemeNames.ABRDN_SUCCESSION:
    case ThemeNames.ABRDN_SHERIDAN:
      const themedIconAbrdn = abrdnAndSubsidiariesIcons[name]
      return (
        themedIconAbrdn || oneXIconLibrary[name] || oneXIconLibrary["Folder"]
      )

    case "JAMES_HAY":
      const themedIconJamesHay = jamesHayIcons[name]
      return (
        themedIconJamesHay || oneXIconLibrary[name] || oneXIconLibrary["Folder"]
      )

    case "LEGADO":
      const themedIconLegado = legadoIcons[name]
      return (
        themedIconLegado || oneXIconLibrary[name] || oneXIconLibrary["Folder"]
      )

    case "FORT_CIRRUS":
      const themedIconFortCirrus = fortCirrusIcons[name]
      return (
        themedIconFortCirrus ||
        oneXIconLibrary[name] ||
        oneXIconLibrary["Folder"]
      )

    case "ONE_X":
      const themedOneXIcon = oneXIconLibrary[name]
      return (
        themedOneXIcon || oneXIconLibrary[name] || oneXIconLibrary["Folder"]
      )

    case "CO_OP":
      const themedCoopIcon = coopIcons[name]
      return (
        themedCoopIcon || oneXIconLibrary[name] || oneXIconLibrary["Folder"]
      )
    case "SCHOFIELD_SWEENEY":
      const themedSchofieldSweeneyIcon = schofiledSweeneyIcons[name]
      return (
        themedSchofieldSweeneyIcon ||
        oneXIconLibrary[name] ||
        oneXIconLibrary["Folder"]
      )
    /**
     *   - NOTE: we are not currently set up sufficiently in the design system code to support multi-coloured icons if they
     *           are also required to also have a different version, i.e welcome-color-1 & welcome-color-2.
     *           Ideally we should be rendering a separate component for each version of the icon.  Currently we would have to configure the same icon
     *           differently for each version, with almost every icon requiring it's own custom config = not scalable or maintainable.
     */

    default:
      return (
        legadoIcons[name] || oneXIconLibrary[name] || oneXIconLibrary["Folder"]
      )
  }
}
