import cloneDeep from "clone-deep"
import { getFolderNotClone } from "../../api/api-client/api-handler"
import { IFile, IFolder } from "../../api/api-client/api-types"

export const addFilesToFolder = ({
  files,
  folders,
}: {
  files: IFile[]
  folders?: IFolder[]
}) => {
  if (!folders) {
    return
  }
  if (!files[0]) {
    return
  }

  const foldersCopy = cloneDeep(folders)

  const selectedFolder = getFolderNotClone({
    folders: foldersCopy,
    folderId: files[0].parentId!,
  })
  if (selectedFolder) {
    selectedFolder.contents = selectedFolder.contents
      ? [...files, ...selectedFolder.contents]
      : files
  }
  return foldersCopy
}
