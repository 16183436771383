export const WpsAdvisoryMobileNavLogo = () => {
  return (
    <svg
      width="76"
      height="26"
      viewBox="0 0 76 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8066_10722)">
        <g clip-path="url(#clip1_8066_10722)">
          <path
            d="M60.8018 11.1758L58.3964 19.2675H56.3701L54.9997 14.4618L53.6108 19.2675H51.5846L49.1792 11.1758H51.1971L52.6463 16.5182L54.1375 11.1758H55.8535L57.3363 16.5182L58.7856 11.1758H60.8035H60.8018Z"
            fill="#000080"
          />
          <path
            d="M65.1378 11.1842C65.9999 11.1842 66.6977 11.4861 67.2328 12.0749C67.7679 12.672 68.0346 13.3363 68.0346 14.0894C68.0346 14.8425 67.7679 15.5252 67.2244 16.1207C66.6809 16.7179 65.9664 17.003 65.069 17.003H63.1551V19.2675H61.2664V11.1758H65.1461H65.1378V11.1842ZM65.017 15.2904C65.3525 15.2904 65.6292 15.1696 65.8439 14.9365C66.0603 14.7033 66.171 14.4266 66.171 14.1062C66.171 13.7858 66.067 13.509 65.8607 13.2759C65.6544 13.0427 65.3944 12.9219 65.0841 12.9219H63.135V15.2988H65.0052L65.0136 15.2904H65.017Z"
            fill="#000080"
          />
          <path
            d="M71.8374 19.4655C70.8897 19.4655 70.0778 19.2323 69.4236 18.7559C68.7678 18.2812 68.3115 17.7009 68.0532 17.0098L69.8732 16.4479C70.0376 16.8454 70.2959 17.1742 70.6582 17.4157C71.0205 17.6573 71.4516 17.7881 71.9598 17.7881C72.3389 17.7881 72.6409 17.7193 72.874 17.5633C73.1072 17.4073 73.228 17.2346 73.228 17.0182C73.228 16.681 72.9428 16.421 72.3574 16.2399L70.2791 15.6008C69.7189 15.428 69.271 15.1512 68.9255 14.7789C68.59 14.4065 68.4256 13.967 68.434 13.4571C68.434 12.7828 68.7108 12.2125 69.2794 11.7462C69.848 11.2798 70.5643 11.0366 71.4264 11.0366C72.2886 11.0366 73.03 11.2446 73.6859 11.6505C74.3417 12.0649 74.7812 12.5664 74.9959 13.1552L73.2363 13.6735C73.072 13.3883 72.8304 13.1552 72.5117 12.9723C72.193 12.7912 71.8391 12.7039 71.4516 12.7039C71.1329 12.7039 70.8746 12.7643 70.675 12.8935C70.477 13.0143 70.3814 13.1786 70.3814 13.3699C70.3814 13.6466 70.5626 13.8362 70.9249 13.9486L72.9596 14.596C73.6657 14.8124 74.2092 15.0892 74.5883 15.4263C74.9674 15.7551 75.1653 16.2399 75.1653 16.8622C75.1653 17.5969 74.8634 18.2024 74.2512 18.6956C73.6389 19.1887 72.8371 19.4302 71.8542 19.4302V19.4655H71.8374Z"
            fill="#000080"
          />
          <path
            d="M13.1844 12.6418L13.2012 12.5965H13.2028L9.40189 1.05273C8.26463 1.35299 7.17936 1.69349 6.15112 2.07091C7.66077 6.97054 9.28447 11.84 11.2537 16.5652V16.5685C11.2839 16.644 11.3175 16.7195 11.3493 16.7967C11.3678 16.8403 11.3846 16.8822 11.4013 16.9258C11.5137 17.1908 11.6244 17.4592 11.7402 17.7242C11.7033 17.8467 11.6546 17.9725 11.5993 18.0966C11.7049 17.8702 11.7636 17.7259 11.7636 17.7259L13.5115 13.6398L13.1827 12.6418H13.1844Z"
            fill="#000080"
          />
          <path
            d="M9.62653 19.5729C9.48563 19.5293 9.29944 19.4471 9.15518 19.3448C8.24269 18.6889 6.90246 14.7035 6.4395 13.4555C5.16133 10.0202 4.02742 6.52792 2.94719 3.02051C1.88037 3.55224 0.90749 4.13094 0.0402832 4.74486C1.21445 8.50891 2.45068 12.2545 3.85968 15.9296C4.38638 17.3068 5.07243 19.3549 6.06712 20.9719C6.28183 21.3191 6.50995 21.7066 6.75988 22.0051C6.76659 22.0051 6.86891 22.0303 6.86891 22.0303C9.32292 22.1947 10.8191 19.6417 11.4498 18.4055C11.0506 19.1402 10.3897 19.7759 9.62653 19.5729Z"
            fill="#000080"
          />
          <path
            d="M18.8738 6.08167C17.069 5.716 16.086 7.09145 15.4302 8.53736C15.3446 8.76548 14.2308 11.7194 13.7394 13.0277C13.4643 13.806 12.6541 15.5958 11.9312 17.2916C11.131 19.1451 9.73211 21.9128 6.8621 21.9631C6.62056 21.9581 6.8621 22.1175 6.9292 22.2131C7.2999 22.614 7.7377 22.9729 8.22246 23.2128C12.9627 25.1669 15.3698 18.2091 16.814 14.3545C17.542 12.3031 19.2898 7.65002 20.8397 7.78421C20.4456 7.04449 19.7293 6.30979 18.9057 6.09509L18.8738 6.08502V6.08167Z"
            fill="#5323EF"
          />
          <path
            d="M24.052 16.3438V16.3404C23.886 15.8875 23.7249 15.433 23.5673 14.9801C23.9648 13.9451 21.8245 19.4771 21.8245 19.4771C21.9821 19.878 22.0677 20.0995 22.0677 20.0995C22.1482 20.2991 22.2337 20.502 22.3226 20.7083C22.9433 22.1559 23.7249 23.7192 24.7213 25.0444C26.0934 24.9706 27.432 24.8448 28.7336 24.6737C26.6956 22.748 25.2396 19.5627 24.0537 16.3438H24.052Z"
            fill="#000080"
          />
          <path
            d="M37.6356 1.74229C36.6073 1.36488 35.5221 1.02437 34.3848 0.724121L30.5838 12.2679L30.5872 12.2763L30.136 13.6484L31.909 17.7278C32.0801 17.3369 32.2428 16.9427 32.4072 16.5519V16.5486C34.4351 11.7244 36.0907 6.75096 37.6372 1.74397L37.6356 1.74229Z"
            fill="#B85F27"
          />
          <path
            d="M43.5466 4.77171C42.6777 4.15611 41.7065 3.57909 40.6397 3.04736C39.5595 6.55477 38.4239 10.0471 37.1474 13.4824C36.6844 14.727 35.3425 18.7158 34.4317 19.3717C34.3059 19.4606 34.1835 19.5209 34.0644 19.5646C33.3951 19.7709 32.8013 19.323 32.3887 18.7175C33.1066 20.0141 34.6246 22.1058 36.8857 21.9531C37.134 21.6546 37.3621 21.3258 37.5785 20.9786C38.5832 19.3582 39.1988 17.3437 39.7306 15.9548C41.1379 12.2797 42.3758 8.53408 43.55 4.77004H43.5483L43.5466 4.77171Z"
            fill="#AF4475"
          />
          <path
            d="M32.3887 18.7157H32.387C32.174 18.4037 32.0096 18.0514 31.9073 17.7244L30.1343 13.645L28.3378 8.83926C28.3378 8.83926 27.9655 8.0274 27.8246 7.79928C25.9929 7.23736 25.3806 10.2298 25.3806 10.2298C25.5467 10.6861 29.4063 22.0604 32.4172 23.248C34.3529 24.0112 35.7871 23.2715 36.8875 21.9497C34.6264 22.1023 33.1083 20.009 32.3904 18.714L32.3887 18.7157Z"
            fill="#000080"
          />
          <path
            d="M27.8246 7.78609C27.1654 6.71928 26.2696 5.95942 25.1626 6.00807H25.1458C25.0502 6.0131 24.9546 6.02652 24.8573 6.04329C24.5654 6.08523 24.2836 6.17916 24.0186 6.32845C23.8727 6.40225 23.5925 6.62031 23.5925 6.62031C23.19 6.95076 22.8511 7.38688 22.6297 7.85822C20.7175 11.9577 18.9848 20.8194 14.9238 24.6639C16.2204 24.835 17.5556 24.9608 18.9244 25.0313C20.0617 23.52 20.9222 21.6933 21.578 20.0863C21.578 20.0863 21.6652 19.8616 21.8212 19.464L25.4226 10.3458C25.4226 10.3458 26.7007 7.54791 27.8212 7.78777H27.8263L27.8246 7.78609Z"
            fill="#4A7D3C"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_8066_10722">
          <rect
            width="75.3145"
            height="24.4898"
            fill="white"
            transform="translate(0 0.640137)"
          />
        </clipPath>
        <clipPath id="clip1_8066_10722">
          <rect
            width="75.3145"
            height="24.4898"
            fill="white"
            transform="translate(0 0.640137)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
