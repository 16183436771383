import { ThemeNames } from "../../contexts/application/constants"

export const themesAvailableOn3TierSystem = [
  ThemeNames.ABRDN_D2C,
  ThemeNames.ABRDN_BALMORAL,
  ThemeNames.ABRDN_CAPITAL,
  ThemeNames.ABRDN_GIBBS,
  ThemeNames.ABRDN_LUDLOW,
  ThemeNames.ABRDN_SHERIDAN,
  ThemeNames.ABRDN_SUCCESSION,
  // ABRDN VANILLA is the default abrdn theme
  ThemeNames.ABRDN_VANILLA,
  "AMADEUS",
  "CO_OP",
  ThemeNames.LIFESTAGE,
  ThemeNames.SCHOFIELD_SWEENEY,
  ThemeNames.WPS_ADVISORY,
]
