// TODO: remove this once we have the correct types
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from "axios"

import {
  BulkDistributionApi,
  DistributionClientsSearchDto,
  DistributionProjectAnalyticsInfoDto,
  DistributionProjectInfoDto,
  StoreDistributionDetailsDto,
} from "legado-generated-api-client"
import { sleep } from "../../utils/helpers"
import { IFile } from "../api-client/api-types"
import { ApiController } from "../apiController"
import { handleErrorAndTokenRefresh } from "../lib/handleErrorAndTokenRefresh"

/* Communications */
export class BulkDistributionController {
  private apiController: ApiController
  constructor(apiController: ApiController) {
    this.apiController = apiController
  }

  private getOptions(withToken: boolean, formData: FormData = new FormData()) {
    type OptionValues = { [key: string]: any }
    type HeaderValues = { [key: string]: string }

    const headerValues: HeaderValues = {}
    if (this.apiController.getApiConnection.apiKey) {
      headerValues["ApiKey"] = this.apiController.getApiConnection.apiKey
    }

    if (withToken) {
      headerValues["Authorization"] = "Bearer " + this.apiController.getApiToken
    }

    const optionValues: OptionValues = {}
    optionValues["headers"] = headerValues
    optionValues["data"] = formData
    optionValues["Content-Type"] = "multipart/form-data"

    return optionValues
  }

  public async deleteCsvCriteria(
    projectId: string,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        await communicationsApi.apiBulkDistributionProjectProjectIdCriteriaRemovePut(
          projectId,
          this.getOptions(true)
        )
        return true
      } catch (error) {
        const shouldRetry = await handleErrorAndTokenRefresh(
          error as AxiosError,
          "Could not Delete Criteria",
          secondAttempt
        )
        if (shouldRetry) return await this.deleteCsvCriteria(projectId, true)
        return false
      }
    } else {
      await sleep(100)
      if (projectId) {
        return true
      } else {
        return false
      }
    }
  }

  public async postBulkUploadProjectDetails(
    projectId: string,
    storeDistributionDetailsDto: StoreDistributionDetailsDto,
    secondAttempt: boolean = false,
    projects?: DistributionProjectAnalyticsInfoDto[]
  ): Promise<boolean | DistributionProjectInfoDto> {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        await communicationsApi.apiBulkDistributionProjectProjectIdSchedulePut(
          projectId,
          storeDistributionDetailsDto,
          false,
          this.getOptions(true)
        )
        return true
      } catch (error) {
        const shouldRetry = await handleErrorAndTokenRefresh(
          error as AxiosError,
          "Could not Delete Criteria",
          secondAttempt
        )
        if (shouldRetry)
          return await this.postBulkUploadProjectDetails(
            projectId,
            storeDistributionDetailsDto,
            true
          )
        return false
      }
    } else {
      await sleep(500)
      const updateStaticProject = projects?.find(
        (project) => project.id === projectId
      )
      if (updateStaticProject) {
        return updateStaticProject
      } else {
        const newProject = {
          id: projectId,
          distributionDateTimeAdviser:
            storeDistributionDetailsDto.distributionDateTimeAdviser,
          distributionDateTimeClient:
            storeDistributionDetailsDto.distributionDateTimeClient,
          isSubAccount: storeDistributionDetailsDto.isSubAccount,
          sendNotification: storeDistributionDetailsDto.sendNotification,
          visibility: storeDistributionDetailsDto.visibility,
        } as DistributionProjectInfoDto
        return newProject
      }
    }
  }

  public async deleteBulkUploadProject(
    projectId: string,
    secondAttempt: boolean = false
  ): Promise<any> {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        await communicationsApi.apiBulkDistributionProjectProjectIdDelete(
          projectId,
          false, //test only
          this.getOptions(true)
        )
        return true
      } catch (error) {
        const shouldRetry = await handleErrorAndTokenRefresh(
          error as AxiosError,
          "Could not Delete Criteria",
          secondAttempt
        )
        if (shouldRetry)
          return await this.deleteBulkUploadProject(projectId, true)
        return false
      }
    } else {
      await sleep(100)
      return true
    }
  }

  public async getProject(
    projectId: string,
    secondAttempt: boolean = false,
    projects?: DistributionProjectAnalyticsInfoDto[]
  ): Promise<DistributionProjectInfoDto | null> {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        const res =
          await communicationsApi.apiBulkDistributionProjectProjectIdGet(
            projectId,
            this.getOptions(true)
          )

        return {
          ...res.data,
          scheduledDateTimeAdviser: res.data.scheduledDateTimeAdviser,
          scheduledDateTimeClient: res.data.scheduledDateTimeClient,
        }
      } catch (error) {
        const shouldRetry = await handleErrorAndTokenRefresh(
          error as AxiosError,
          "Could not get project",
          secondAttempt
        )
        if (shouldRetry) return await this.getProject(projectId, true)
        return null
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async deleteFileBulkDistribution(
    projectID: string,
    fileIDs: Array<string>,
    secondAttempt: boolean = false
  ): Promise<any> {
    if (this.apiController.connected) {
      try {
        const bulkDistributionApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        const response =
          await bulkDistributionApi.apiBulkDistributionProjectProjectIdFileRemovePut(
            projectID,
            fileIDs,
            this.getOptions(true)
          )
        return response.data
      } catch (error) {
        const shouldRetry = await handleErrorAndTokenRefresh(
          error as AxiosError,
          "Could not upload",
          secondAttempt
        )
        if (shouldRetry)
          return await this.deleteFileBulkDistribution(projectID, fileIDs, true)
        const errorResponse = {
          error: (error as AxiosError).response?.data,
        }
        return [errorResponse]
      }
    }
  }

  public uploadNewFileVersion = async ({
    newVersionfile,
    projectId,
    fileId,
    secondAttempt,
    sendNotification,
  }: {
    newVersionfile: IFile
    projectId: string
    fileId: string
    secondAttempt: boolean
    sendNotification: boolean
  }) => {
    if (this.apiController.connected) {
      let wrongFileType = false
      const errorResponses = [] as IFile[]
      try {
        const bulkDistributionApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })
        const formData = new FormData()
        if (newVersionfile.extension === "pdf") {
          newVersionfile.name = newVersionfile.name.replace(/'/g, "`")
          const blob = new Blob([newVersionfile.binaryStr!], {
            type: newVersionfile.mimeType ?? undefined,
          })
          formData.append("file", blob, newVersionfile.name)
        } else {
          wrongFileType = true
          const errorResponse = {
            id: newVersionfile.id,
            name: newVersionfile.name,
            extension: newVersionfile.extension,
            fileType: "ERROR",
            fileName: "File type not supported",
            hasThumbnail: false,
            hasPreview: false,
          }
          errorResponses.push(errorResponse)
        }

        if (wrongFileType) {
          return errorResponses
        }

        await bulkDistributionApi.apiBulkDistributionProjectProjectIdFileFileIdVersionNewPut(
          projectId,
          fileId,
          sendNotification,
          this.getOptions(true, formData)
        )
        return await this.getProject(projectId, true)
      } catch (error) {
        const shouldRetry = await handleErrorAndTokenRefresh(
          error as AxiosError,
          "Could not upload",
          secondAttempt
        )
        if (shouldRetry) return null
      }
    } else {
      /**
       * The sleep is required otherwise the testing pipeline fails with the following error
       * (it fails despite the test passing):
       * ●  Cannot log after tests are done. Did you forget to wait for something async in your test?
       *    Attempted to log "Do not use static data, use MSW instead".
       */
      await sleep(1)
      // TODO: change to throw new Error("Do not use static data, use MSW instead")
      console.error("Do not use static data, use MSW instead")
      return null
    }
  }

  public async updateVersionBulkDistribution(
    projectID: string,
    fileID: string,
    newVersion: number,
    secondAttempt: boolean = false
  ): Promise<any> {
    if (this.apiController.connected) {
      try {
        const bulkDistributionApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        const response =
          await bulkDistributionApi.apiBulkDistributionProjectProjectIdFileFileIdVersionUpdateNewVersionPut(
            projectID,
            fileID,
            newVersion,
            this.getOptions(true)
          )
        return response.data
      } catch (error) {
        const shouldRetry = await handleErrorAndTokenRefresh(
          error as AxiosError,
          "Could not update version",
          secondAttempt
        )
        if (shouldRetry)
          return await this.updateVersionBulkDistribution(
            projectID,
            fileID,
            newVersion,
            true
          )
        const errorResponse = {
          error: (error as AxiosError).response?.data,
        }
        return [errorResponse]
      }
    } else {
      return "Not connected to server"
    }
  }

  public async revokeDocument(
    fileId: string,
    projectId: string,
    secondAttempt: boolean = false
  ) {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        const res =
          await communicationsApi.apiBulkDistributionProjectProjectIdFileFileIdRevokePut(
            projectId,
            fileId,
            this.getOptions(true)
          )
        return res.data
      } catch (error) {
        const shouldRetry = await handleErrorAndTokenRefresh(
          error as AxiosError,
          "Could not revoke document",
          secondAttempt
        )
        if (shouldRetry) return await this.getProject(projectId, true)
        return undefined
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async deleteClientsFromProject(
    projectId: string,
    emails: string[]
  ): Promise<boolean> {
    if (this.apiController.connected) {
      try {
        const deleteApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        await deleteApi.apiBulkDistributionProjectProjectIdUsersPatch(
          projectId,
          emails,
          this.getOptions(true)
        )
        return true
      } catch (error) {
        return false
      }
    } else {
      await sleep(100)
      if (projectId && emails) {
        return true
      } else {
        return false
      }
    }
  }

  public async deleteProject(
    projectId: string,
    secondAttempt: boolean = false
  ): Promise<boolean | null> {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        const res =
          await communicationsApi.apiBulkDistributionProjectProjectIdDelete(
            projectId,
            false, //test only
            this.getOptions(true)
          )
        return res.status === 200
      } catch (error) {
        const shouldRetry = await handleErrorAndTokenRefresh(
          error as AxiosError,
          "Could not delete project",
          secondAttempt
        )
        if (shouldRetry) {
          return await this.deleteProject(projectId, true)
        }
        return false
      }
    } else {
      await sleep(100)
      return true
    }
  }

  public async revokeClientFromProject(
    projectId: string,
    userId: string,
    secondAttempt: boolean = false
  ): Promise<DistributionProjectInfoDto | null> {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        const res =
          await communicationsApi.apiBulkDistributionProjectProjectIdUserUserIdRevokeAllPut(
            projectId,
            userId,
            this.getOptions(true)
          )
        return res.data
      } catch (error) {
        const shouldRetry = await handleErrorAndTokenRefresh(
          error as AxiosError,
          "Could not revoke document",
          secondAttempt
        )
        if (shouldRetry) return await this.getProject(projectId, true)
        return null
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async updateProjectName(
    projectId: string,
    newName: string,
    secondAttempt: boolean = false,
    projects?: DistributionProjectAnalyticsInfoDto[]
  ) {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })
        const res =
          await communicationsApi.apiBulkDistributionProjectProjectIdNamePatch(
            projectId,
            newName,
            this.getOptions(true)
          )
        return res.data
      } catch (error) {
        const shouldRetry = await handleErrorAndTokenRefresh(
          error as AxiosError,
          "Could not revoke document",
          secondAttempt
        )
        if (shouldRetry) return await this.getProject(projectId, true)
        return null
      }
    } else {
      await sleep(100)
      const staticProject = projects?.find(
        (staticProject) => staticProject.id === projectId
      )
      const updatedProject = {
        ...staticProject,
        name: newName,
        status: staticProject?.status,
        readCount: 0,
        sizeOfDistribution: staticProject?.sizeOfDistribution,
        sendDateAdviser: staticProject?.sendDateAdviser,
        sendDateClient: staticProject?.sendDateClient,
      } as DistributionProjectAnalyticsInfoDto
      const updatedProjects = projects?.map((x) => {
        if (x?.id && x?.id === projectId) {
          return updatedProject
        }
        return x
      })
      return updatedProjects ?? []
    }
  }

  public async getClientsListFromCsv(
    projectId: string,
    start: number,
    numberOfClients: number,
    sortDesc: boolean,
    search?: string,
    secondAttempt: boolean = false
  ): Promise<DistributionClientsSearchDto> {
    if (this.apiController.connected) {
      try {
        const api = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })
        const response =
          await api.apiBulkDistributionProjectProjectIdClientsGet(
            projectId,
            start,
            numberOfClients,
            sortDesc,
            search,
            this.getOptions(true)
          )
        return response.data
      } catch (error) {
        const shouldRetry = await handleErrorAndTokenRefresh(
          error as AxiosError,
          "Could not get client list from CSV",
          secondAttempt
        )
        if (shouldRetry)
          return await this.getClientsListFromCsv(
            projectId,
            start,
            numberOfClients,
            sortDesc,
            search,
            true
          )
        return {}
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async updateProjectVisibility(
    projectId: string,
    newVisibility: string,
    secondAttempt: boolean = false
  ): Promise<DistributionProjectInfoDto | null> {
    if (this.apiController.connected) {
      try {
        const api = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })
        const response =
          await api.apiBulkDistributionProjectProjectIdVisibilityNewVisibilityPatch(
            projectId,
            newVisibility,
            this.getOptions(true)
          )
        return response.data
      } catch (error) {
        const shouldRetry = await handleErrorAndTokenRefresh(
          error as AxiosError,
          "Could not update project visibility",
          secondAttempt
        )
        if (shouldRetry)
          return await this.updateProjectVisibility(
            projectId,
            newVisibility,
            true
          )
        return null
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async updateProjectDistributionDetails(
    projectId: string,
    storeDistributionDetailsDto: StoreDistributionDetailsDto,
    secondAttempt: boolean = false,
    projects?: DistributionProjectAnalyticsInfoDto[]
  ): Promise<boolean | DistributionProjectInfoDto> {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        await communicationsApi.apiBulkDistributionProjectProjectIdSaveprojectdetailsPut(
          projectId,
          storeDistributionDetailsDto,
          this.getOptions(true)
        )
        return true
      } catch (error) {
        const shouldRetry = await handleErrorAndTokenRefresh(
          error as AxiosError,
          "Could not update project details",
          secondAttempt
        )
        if (shouldRetry)
          return await this.updateProjectDistributionDetails(
            projectId,
            storeDistributionDetailsDto,
            true,
            projects
          )
        return false
      }
    } else {
      await sleep(500)
      const updateStaticProject = projects?.find(
        (project) => project.id === projectId
      )
      if (updateStaticProject) {
        return updateStaticProject
      } else {
        const newProject = {
          id: projectId,
          distributionDateTimeAdviser:
            storeDistributionDetailsDto.distributionDateTimeAdviser,
          distributionDateTimeClient:
            storeDistributionDetailsDto.distributionDateTimeClient,
          isSubAccount: storeDistributionDetailsDto.isSubAccount,
          sendNotification: storeDistributionDetailsDto.sendNotification,
          visibility: storeDistributionDetailsDto.visibility,
        } as DistributionProjectInfoDto
        return newProject
      }
    }
  }
}
