import { useEffect, useRef } from "react"
import { Navigate, useLocation } from "react-router-dom"

import {
  PAGE_PATH,
  UserModuleType,
} from "../../../contexts/application/constants"
import {
  setDisplaySuccessOrErrorMessage,
  useToastContext,
} from "../../../contexts/toasts"
import { useUserContext } from "../../../contexts/users"
import { notificationMessages } from "../../../data/notificationMessages"
import { castToBool } from "../../../utils/helpers"

interface RedirectProps {
  basePath?: string
}
export const Redirect = ({ basePath }: RedirectProps) => {
  const { search, pathname } = useLocation()
  const initialPath = useRef(pathname)
  const { dispatch: toastDispatch } = useToastContext()
  const {
    userState: { currentUser },
  } = useUserContext()

  useEffect(() => {
    if (!currentUser || search) return
    const isValidPath = Object.values(PAGE_PATH).includes(initialPath.current)
    if (!isValidPath) {
      toastDispatch(
        setDisplaySuccessOrErrorMessage({
          message: notificationMessages.invalidRoute.ERROR,
          messageType: "ERROR",
        })
      )
    }
  }, [currentUser, search, toastDispatch])

  const searchParams = Object.fromEntries(new URLSearchParams(search).entries())

  const getPagePathForModules = () => {
    //fnz account uses "context" to get a vault without a specific client associated with it
    if (
      (currentUser?.modules?.includes(
        UserModuleType.DOCUMENTS_LIBRARY_ADVISER_VIEW
      ) ||
        currentUser?.modules?.includes(UserModuleType.DOCUMENT_LIBRARY)) &&
      searchParams?.context?.length > 0
    ) {
      return PAGE_PATH.AccountDocumentLibraryPage + search
    }
    const runningInDock = castToBool(process.env.REACT_APP_RUNNING_IN_DOCK)
    switch (true) {
      case currentUser?.modules?.includes(UserModuleType.ORGANISATIONS_ADMIN):
        return PAGE_PATH.Organisations

      case currentUser?.modules?.includes(UserModuleType.DOCUMENT_HUB):
        return runningInDock
          ? `${basePath}${PAGE_PATH.DocumentHubPage}`
          : PAGE_PATH.DocumentHubPage

      case currentUser?.modules?.includes(UserModuleType.DOCUMENT_LIBRARY):
        return PAGE_PATH.AccountDocumentLibraryPage

      case currentUser?.modules?.includes(UserModuleType.VAULT):
        return PAGE_PATH.HomePage

      case currentUser?.modules?.includes(UserModuleType.CLIENT_LIST):
        return PAGE_PATH.ClientsPage + search

      case currentUser?.modules?.includes(UserModuleType.SHARED_WITH_ME_LIST):
        return PAGE_PATH.SharedWithMePage + search

      case currentUser?.modules?.includes(UserModuleType.FLAT_FILE_LIST) &&
        currentUser?.modules?.includes(UserModuleType.CLIENT_LIST):
        return PAGE_PATH.ClientsPage

      case currentUser?.modules?.includes(UserModuleType.FLAT_FILE_LIST) &&
        !currentUser?.modules?.includes(UserModuleType.CLIENT_LIST):
        return PAGE_PATH.HomePage

      default:
        return PAGE_PATH.ErrorPage
    }
  }

  return <Navigate replace to={getPagePathForModules()} />
}
