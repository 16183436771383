/**
 * From BE:
        None = 0,
        // Token is passed in
        Token = 1,
        // Login Via Email
        Passwordless = 2,
        // User Password Login
        Password = 3,
        // Password or Passwordless
        Mixed = 4,
        // External SSO scheme
        SSO = 5
 */
export enum loginTypes {
  // Used to authenticate users with a Password login page (COOP only at the moment)
  PASSWORD = "Password",
  // Used to authenticate users with a SSO code from url (FNZ - Quilter, Aberdeen, etc.) At the moment we use SSO for QA/PP/Prod but for DEV we use Token (we might make this better in the future and use SSO for DEV as well)
  SSO = "SSO",
  // Used to authenticate users with a Token from url - Used by the Middle end (MVC .net app) - Legado, WPS, TFE etc.
  TOKEN = "Token",
  // not in use
  PASSWORDLESS = "Passwordless",
  // not in use
  MIXED = "Mixed",
  // not in use
  NONE = "None",
}
