/* istanbul ignore file */

import React from "react"
import ReactDOM from "react-dom/client"

import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { msalConfig } from "./ms-identity/authConfig"

import * as Sentry from "@sentry/react"

import { registerLicense } from "@syncfusion/ej2-base"

import App from "./App"
import "./index.css"

// Only initialize Sentry if the URL is set
if (
  process.env.REACT_APP_SENTRY_URL !== undefined &&
  process.env.REACT_APP_SENTRY_URL !== ""
) {
  try {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_URL,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.captureConsoleIntegration({
          // array of methods that should be captured
          levels: ["info", "warn", "error"],
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: parseFloat(process.env.REACT_APP_TRACES_SAMPLE_RATE), // Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: parseFloat(
        process.env.REACT_APP_REPLAY_SESSION_SAMPLE_RATE
      ), // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: parseFloat(
        process.env.REACT_APP_REPLACE_ERROR_SAMPLE_RATE
      ), // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      environment: process.env.REACT_APP_ENV,
    })
  } catch (error) {
    console.error("Error initializing Sentry", error)
  }
}

const syncfusionLicenceKey =
  "Ngo9BigBOggjHTQxAR8/V1NAaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH1feHVRRWhYUkN3V0s="
registerLicense(syncfusionLicenceKey)

async function enableApiMocking() {
  if (process.env.REACT_APP_MSW_ENABLED === "1") {
    const { worker } = await import("./mocks/browser")
    await worker.start()
  }
}

const msalInstance = new PublicClientApplication(msalConfig)

const AppStrict = () => {
  return (
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </React.StrictMode>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"))

enableApiMocking().then(() => {
  root.render(<AppStrict />)
})
