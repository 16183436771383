// TODO: remove this once we have the correct types
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Industry } from "legado-generated-api-client/models"
import industries from "../staticData/getIndustries.json"

type TGetIndustries = () => Industry[]

const isIndustryDtos = (industries: any) => {
  if ((industries as Industry[])?.length) {
    return industries as Industry[]
  }
}

export const getIndustriesFromLocalData: TGetIndustries = () => {
  return isIndustryDtos(industries) || []
}
