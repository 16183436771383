const ZoomOut = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4609 13.7588L18.9994 19.2973"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.85689 15.0107C11.6438 15.0107 14.7138 11.9407 14.7138 8.15377C14.7138 4.36681 11.6438 1.29688 7.85689 1.29688C4.06994 1.29688 1 4.36681 1 8.15377C1 11.9407 4.06994 15.0107 7.85689 15.0107Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="4.85547" y="7.65332" width="6" height="1" rx="0.5" fill="black" />
  </svg>
)

export default ZoomOut
